import {React,useState,useEffect,forwardRef} from 'react'
import MaterialTable from '@material-table/core'
import swal from 'sweetalert';
import Cookies from 'universal-cookie/es6';
import Navbar2 from './Navbar'
import {Redirect,useHistory} from 'react-router-dom'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Print from '@material-ui/icons/Print';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const api=process.env.REACT_APP_API;

function Cancelar() {

    let History = useHistory();

    const cookies=new Cookies();
    const rol=cookies.get("nombre_Rol");
    const [solicitudes,setSolicitudes]=useState([])
    const [cambio,setCambio]=useState(false)


    const columns = [
        { title: "Código de Solcitud", field: "id_Solicitud" },
        { title: "Edificio", field: "edificio" },
        { title: "Nombre de Sala", field: "nombre_Sala" },
        { title: "N° de piso", field: "n_Piso" },
        { title: "Tamaño de Sala", field: "Tamaño_sala" },
        { title: "Día", field: "dia" },
        { title: "Hora de Inicio", field: "hora_Inicio" },
        { title: "Hora de Finalización", field: "hora_Fin" },
        { title: "Estado", field: "confirmacion" },
        { title: "Usuario", field: "correo_Electronico" },
    ];

    const tableIcons = {
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        Print: forwardRef((props, ref) => <Print {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    }



    const getReservas=async()=>{

        const res = await fetch(`${api}/reservasAceptadas`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              rol
            })
        })                      

        const data = await res.json();
      
        setSolicitudes(data)
    }

    useEffect(() => {
        getReservas()
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cambio])


    if(rol==="Usuario"){
        return <Redirect to="/inicio"></Redirect>
    }else{
      return (
    <div>
        <Navbar2></Navbar2>
        <div>
            <MaterialTable
             options={{
                actionsColumnIndex: -1, headerStyle: {
                    backgroundColor: '#4484ce',
                    color: '#FFF'
                },
                maxBodyHeight: '600px'
            }}
                icons={tableIcons}
                data={solicitudes}
                columns={columns}
                title="Reservas de Salas"
                actions={[{
                    icon: DeleteOutline,
                    tooltip: 'Cancelar Reserva',
                    onClick: async (event, rowdata) => {
                        const id = rowdata.id_Solicitud;

                        const res = await fetch(`${api}/cancelarReserva`, {
                            method: 'put',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                id
                            })
                        })
                        
                        const data = await res.json();
                        
                        if(data==="Cancelado"){
                            swal({
                                title: "Estado de Solicitud de Reserva",
                                text: "Se Realizó el cambio en la Reserva",
                                icon: "success",
                                button: "Aceptar"
                            }).then(()=>{
                                if(cookies.get("correo_Electronico")==="undefined"){
                                    
                                    History.push("./");
                                  }else{
                                   setCambio(!cambio)
                                  }
                            })
                            

                        }else if(data==="Nocancelado"){
                            swal({
                                title: "Estado de Solicitud de Reserva",
                                text: "No se Realizó el cambio a Reserva Cancelada",
                                icon: "error",
                                button: "Aceptar"
                            })
                        }
                        
                    }
                }]}
            >

            </MaterialTable>
        </div>
    </div>
    )
      }
}

export default Cancelar