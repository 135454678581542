import React from 'react'
import { Contenedorimagen } from './ContenedorImagen'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Contenedor } from './Contenedor'
import Navbar2 from './Navbar'
import "../Appv2"

function Inicio() {
    return (
        <div className='app2'>
            <Navbar2 className=""/>
            <Contenedorimagen className="imagen">
            
            <Contenedor className=''>
                <div className="text-center" style={{fontSize:"10em", color:"white"}}>
                    <FontAwesomeIcon icon={faEdit} />
                </div>


                <div className='container d-flex justify-content-center aling-items-center'>
                        <div>
                            <p className='letra'>La presente página web es para gestionar la reserva de salas en los diferentes edificios pertenecientes al Centro Cívico Gubernamental, con ella se busca crear un proceso que beneficie a todas las partes involucradas partiendo de la responsabilidad de parte del usuario a la hora de crear solicitudes como de la responsabilidad de parte de los administradores para atender dichas solicitudes.</p>
                            <p></p>
                        </div>
                    
                </div>
            </Contenedor>
            

           </Contenedorimagen>           
        </div>
        
    )
}

export default Inicio
