import React, { useState,useEffect } from 'react';
import {Form,Button, FormGroup} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import {useHistory} from "react-router-dom";
import swal from 'sweetalert';
const api=process.env.REACT_APP_API;


function Formulario() {
  let History = useHistory();
    const [instituciones,setInstituciones]=useState([]);
    const [area,setArea]=useState([]);
    const [nombre,setNombre]=useState("");
    const [apellido,setApellido]=useState("");
    const [nombreUsuario,setNombreUsuario]=useState("");
    const [correo,setCorreo]=useState("");
    const [telefono,setTelefono]=useState("");
    const [edificio,setEdificio]=useState("");
    const [institucion,setInstitucion]=useState("");
    const [nombreArea,setNombreArea]=useState("");
    const [contrasena,setContrasena]=useState("");
    const [cContrasena,setCContrasena]=useState("");
 
    const consultarArea = async () => {
      const res = await fetch(`${api}/area`);
      const data = await res.json();
      setArea(data);
    };



    const enviar=async(e)=>{
      e.preventDefault();

        if(nombre==="" || apellido==="" || nombreUsuario==="" || correo==="" || telefono==="" || edificio==="" || institucion==="" || nombreArea==="" || contrasena==="" || cContrasena===""){
           swal({
              title: "Validacion de Datos",
              text: "Todos los campos deben de estar llenos",
              icon: "warning",
              button: "Aceptar"
            })
          }else if(contrasena.length<8){
            swal({
              title: "Validacion de Datos",
              text: "La contraseña debe de Tener Mas de 8 Caracteres",
              icon: "warning",
              button: "Aceptar"
            })
          }else if(contrasena!==cContrasena){ 
            swal({
              title: "Validacion de Datos",
              text: "La Contraseña no Coincide con la Confirmación",
              icon: "warning",
              button: "Aceptar"
            })
          }else{

            swal({
              text: 'Ingrese Código de Verificación de Su Institución',
              content: "input",
              button: {
                text: "Agregar",
                closeModal: false,
              },
            }).then(async codigo=>{
              const res = await fetch(`${api}/consultarCodigo`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    institucion,
                    codigo
                })
              })    


              const data = await res.json();

              if(data[0]==="Correcto"){
                const res = await fetch(`${api}/agregarInfoUsuario`, {
                  method: 'PUT',
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                      nombre,
                      apellido,
                      nombreUsuario,
                      correo,
                      contrasena,
                      cContrasena,
                      telefono,
                      edificio,
                      institucion,
                      nombreArea
                  })
                  
            })
            
            const data = await res.json();
            if(data[0]==="Rechazado"){
              swal({
                title: "Error al Crear Usuario",
                text: " Ya existe Usuario Asociado al correo Electónico: "+correo,
                icon: "error",
                button: "Aceptar"
              })
            }else if(data[0]==="Confirmado"){
              
              swal({
                title: "Usuario "+correo+ " Creado Exitosamente",
                icon: "success",
                button: "Aceptar"
              })

               await fetch(`${api}/confirmacion`, {
                method: 'Post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nombre,
                    apellido,
                    nombreUsuario,
                    correo,
                })
              })
  
              setNombre("")
              setApellido("")
              setNombreUsuario("")
              setCorreo("")
              setContrasena("")
              setCContrasena("")
              setTelefono("")
              setEdificio("")
              setInstitucion("")
              setNombreArea("")
  
              setTimeout(()=>History.push(`/inicio`),2000);
              
            }
  
              }else{
  
                swal({
                  title: "Error al Crear Usuario",
                  text: "No se Puede Crear el Usuario Porque el Código Ingresado es Incorrrecto",
                  icon: "error",
                  button: "Aceptar"
                })
              }
  

            })
      
           
          

        }
    }

    useEffect(() => {
      consultarArea()
    }, [])

  return (
    <div>
      <div className="container d-flex justify-content-center align-content-center pt-1">
        <h3 className="">Ingreso de Información de Usuario</h3>
      </div>

      <div className="container d-flex justify-content-center align-content-center pt-1">
        <div
          className="bg-secondary justify-content-center d-flex align-content-center pt-4 bg-primary rounded-circle"
          style={{ width: "250px", height: "250px", marginBottom: "15px" }}
        >
          <FontAwesomeIcon
            style={{ color: "white", fontSize: "150px", marginTop: "20px" }}
            icon={faUser}
          />
        </div>
      </div>

      <div className="container  col-md-6 ">
        <form onSubmit={enviar}>
        <div className=" d-flex ">
          <FormGroup className="col-md-6" style={{ marginRight: "25px" }}>
            <Form.Label className="m-0">Nombre</Form.Label>
            <Form.Control
              value={nombre}
              className="mb-2"
              type="text"
              placeholder='Juan'
              onChange={(e) => setNombre(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Form.Label className="m-0">Apellido</Form.Label>
            <Form.Control
              value={apellido}
              className="mb-2"
              type="text"
              placeholder='López'
              onChange={(e) => setApellido(e.target.value)}
            />
          </FormGroup>
        </div>
        <div className=" d-flex ">
          <FormGroup className="col-md-6" style={{ marginRight: "25px" }}>
            <Form.Label className="m-0">Nombre de Usuario</Form.Label>
            <Form.Control value={nombreUsuario} className="mb-2" type="text" placeholder='Juan López' onChange={(e) => setNombreUsuario(e.target.value)} />
          </FormGroup>

          <FormGroup className="col-md-6">
            <Form.Label className="m-0">Correo Electronico</Form.Label>
            <Form.Control className="mb-2" type="email" value={correo}  placeholder='juanlopez@gmail.com' onChange={(e) => setCorreo(e.target.value)}/>
          </FormGroup>
        </div>

        <div className=" d-flex ">
          <FormGroup className="col-md-6" style={{ marginRight: "25px" }}>
            <Form.Label className="m-0">Contraseña</Form.Label>
            <Form.Control className="mb-2" type="password" value={contrasena}   placeholder='********' onChange={(e) => setContrasena(e.target.value)}/> 
          </FormGroup>

          <FormGroup className="col-md-6">
            <Form.Label className="m-0">Confirmar Contraseña</Form.Label>
            <Form.Control className="mb-2" type="password" value={cContrasena} placeholder='********' onChange={(e) => setCContrasena(e.target.value)}/> 
          </FormGroup>
        </div>



        <div className=" d-flex ">
          <FormGroup className="col-md-6" style={{ marginRight: "25px" }}>
            <Form.Label className="m-0">Teléfono</Form.Label>
            <Form.Control  className="mb-2" type="text" value={telefono} placeholder='####-####' onChange={(e) => setTelefono(e.target.value)}/>
          </FormGroup>

          <FormGroup className="col-md-6">
            <Form.Label className="m-0">Edificio</Form.Label>
            <Form.Select className="mb-2"
            onChange={async (e) => {

              const nombre = e.target.value;
              const res2 = await fetch(`${api}/instituciones`,{
                method: "put",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  nombre,
                }),
              });

              const data = await res2.json();
              setInstituciones(data);
              setEdificio(nombre)}}>
              <option>Seleccione una Opción</option>
              <option>Torre 1</option>
              <option>Torre 2</option>
              <option>CBB</option>
              <option >CBC</option>
            </Form.Select> 
          </FormGroup>
        </div>

        <div className=" d-flex ">
          <FormGroup className="col-md-6" style={{ marginRight: "25px" }}>
            <Form.Label className="m-0">Institución</Form.Label>
            <Form.Select className="mb-2"  onChange={(e) => setInstitucion(e.target.value)} >
              <option>Seleccione una Opción</option>
              {
                  instituciones.map(lista=>(
                      <option key={lista.nombre_Institucion}>{lista.nombre_Institucion}</option>
                  )
                )
              }
            </Form.Select>
          </FormGroup>

          <FormGroup className="col-md-6">
            <Form.Label className="m-0">Area de Trabajo</Form.Label>
            <Form.Select className="mb-2"  onChange={(e) => setNombreArea(e.target.value)}>
              <option>Seleccione una Opción</option>

              {
                  area.map(lista=>(
                  <option key={lista.nombre_Are}>{lista.nombre_Are}</option>
                  )
                  )
              }

            </Form.Select>
          </FormGroup>
        </div>

        <FormGroup className="offset-3 col-md-6">
          <Button className="form-control mb-2" variant="success" type='onsubmit'>Crear Usuario</Button>
        </FormGroup>
        </form>
      </div>
      
    </div>
  );
}

export default Formulario;
