import {React, useState, useEffect} from 'react';
import {Form,Button, FormGroup, FormLabel, FormControl, FormSelect,Table} from "react-bootstrap";
import Navbar2 from './Navbar'
import swal from 'sweetalert';
import '../App.css'
const api=process.env.REACT_APP_API;


function Disponibles() {

const [listaE,setListaE]=useState([])
const [nombre_E,setNombre_E]=useState([])
const [edificio,setEdificio]=useState("")
const [sala,setSala]=useState("")
const [dia,setDia]=useState("")
const [mostrar,setMostrar]=useState(false);
const [datos,setDatos]=useState([]);



const consultarEdificio=async()=>{
    const res = await fetch(`${api}/edificios`)
    const data = await res.json();
    setListaE(data)
}

useEffect(()=>{
    consultarEdificio();
})

const consultar=async (e)=>{
    e.preventDefault();
    if(edificio==="" || sala==="" ||dia==="" ){
        swal({
            title: "Validacion de Datos",
            text: "Todos los campos deben de estar llenos",
            icon: "warning",
            button: "Aceptar"
          })
    }else{

      const res=await fetch(`${api}/disponibilidad`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
           edificio,
           sala,
           dia
        })
        })

        const data = await res.json();

        if(data.length===0){
          swal({
            title: "Está Disponible",
            text: "La Sala con Nombre: "+sala+ " Ubicada en el Edificio "+ edificio + " Está Disponible en Todos los Horarios el Día "+dia +".",
            icon: "success",
            button: "Aceptar"            
          })
          setMostrar(false);

        }else{
         setDatos(data);
         setMostrar(true);
        }



    }
}


return <div className='app2'>
    <Navbar2/>
      <div className='  h-100vh container d-flex justify-content-center align-items-center p-2 flex-column'>
          <h3>Consulta de Disponibilidad de Salas</h3>
      </div>
        <div className='container col-md-6'>
        <Form onSubmit={consultar}>
            <FormGroup>
                <FormLabel style={{margin:"0px"}}>Nombre de Edificio</FormLabel>
                <FormSelect value={edificio} onChange={async (e)=>{setEdificio(e.target.value); 
            
            const nombre=e.target.value
            const res2 =await fetch(`${api}/sala`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nombre
                })

            })

            const data = await res2.json();
            setNombre_E(data)
        }
                }>
                    <option>Seleccione una Opción</option>
                    
                    {
                         listaE.map(lista=>(
                            <option key={lista.edificio}>{lista.edificio}</option>
                        )
                      )
                    }
                    
                </FormSelect>
            </FormGroup>

            <FormGroup>
                <FormLabel className='pt-2' style={{margin:"0px"}}>Nombre de Sala</FormLabel>
                <FormSelect value={sala} onChange={async (e)=>{setSala(e.target.value)}}>
                    <option>Seleccione una Opción</option>
                    {
                  nombre_E.map(lista=>(
                      <option key={lista.nombre_Sala}>{lista.nombre_Sala}</option>
                  )
                )
              }
                    </FormSelect>
            </FormGroup>

            <FormGroup> 
                <FormLabel className='pt-2' style={{margin:"0px"}} >Día a Consultar</FormLabel>
                <FormControl type='date' value={dia} onChange={(e)=>{setDia(e.target.value)}}></FormControl>
            </FormGroup>    
            
        

          <FormGroup className='pt-4 pb-3'>
              <Button className='col-md-12' type='onsubmit'>Consultar</Button>
          </FormGroup>

          </Form>
        </div>   

        <div className='container col-md-12 responsive pb-3'>

            { mostrar ? ( 
            

            <div>

            <h3>Solicitudes Aceptadas</h3>
              <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>Día</th>
                  <th>Hora de Inicio</th>
                  <th>Hora de Finalización</th>
                </tr>
              </thead>  
              <tbody>
                {
                     datos.map(lista=>(
                      <tr>
                        <td>{dia}</td>
                        <td>{lista.hora_Inicio}</td>
                        <td>{lista.hora_Fin}</td>
                      </tr>
                  )
                )
                }
              </tbody>
              </Table>

              </div>
            ):null}
        </div>   

  </div>;
}

export default Disponibles;
