import React from 'react';
import ZonaD from './ZonaD'
import Navbar2 from './Navbar'
import defaultImagen from '../assets/zonaDescarga.jpg'

function Zonadescarga() {
    const cards=[
        {
            id:24,
            Nombre:'Zona de Descarga',
            Edificio:'Torre 2',
            N_piso:'No aplica',
            Tamaño:'Grande',
            N_personas:'10',
            Estado:'Disponible',
            img:defaultImagen,
            img2:defaultImagen
          
        }
    ]


  return <div>
    <Navbar2/>
    <div className='container d-flex'>
      <div className='container col-md-12 p-2 '>
            <div className='row col-md-12 d-flex justify-content-center align-items-center'>
            {
                  cards.map(card=>(
                    <div className="col-md-10" key={card.id}>
                    <ZonaD id={card.id} Nombre={card.Nombre} Edificio={card.Edificio} N_piso={card.N_piso} Tamaño={card.Tamaño} N_personas={card.N_personas} Estado={card.Estado} img={card.img} img2={card.img2} ></ZonaD>
                    </div>
                  ))
              }  
              
            </div>
      </div>

  </div>
  </div>
}

export default Zonadescarga;
