import {React,useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import {Form} from "react-bootstrap";
import { Contenedorimagen2 } from './ContenedorImagen2'
import swal from 'sweetalert';
import '../App.css'
import '../login.css'
import Cookies from 'universal-cookie/es6';
import logo from '../assets/logoOperadora.jpg'
import {NavLink,useHistory} from "react-router-dom";
const api=process.env.REACT_APP_API;



function Login() {

  let History = useHistory();
  const cookies=new Cookies();
    
    const [contraseña,setContraseña]=useState("");
    const [correo_Electronico,setCorreo_Electronico]=useState("")
    const [mostrar, setMostrar] = useState(false)
    
    const authentication=async e=>{
      const res = await fetch(`${api}/usuario`,{
        method: 'Put',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          correo_Electronico,
            contraseña,           
        })})
      const data = await res.json();
        console.log(data);
      if(data.length===0){
        setMostrar(true);
      }else if(data.length!==0){
        setMostrar(false)
        var respuesta=data[0];
        cookies.set("correo_Electronico",respuesta.correo_Electronico,{path:"/"})
        cookies.set("nombre",respuesta.nombre,{path:"/"})
        cookies.set("apellido",respuesta.apellido,{path:"/"})
        cookies.set("nombre_Usuario",respuesta.nombre_Usuario,{path:"/"})
        cookies.set("telefono",respuesta.telefono,{path:"/"})
        cookies.set("Nombre_Edificio",respuesta.Nombre_Edificio,{path:"/"})
        cookies.set("nombre_Institucion",respuesta.nombre_Institucion,{path:"/"})
        cookies.set("nombre_Are",respuesta.nombre_Are,{path:"/"})
        cookies.set("nombre_Rol",respuesta.nombre_Rol,{path:"/"})
        
          if(cookies.get("correo_Electronico")==="undefined"){        
            History.push(`/`)
          }else{
            History.push(`/inicio`)
          }
      }
   }
  
    const handleSubmit =(e) => {
      e.preventDefault();
      if (correo_Electronico==="" || contraseña === "") {  
        swal({
          title: "Validacion de Datos",
          text: "Todos los campos deben de estar llenos",
          icon: "warning",
          button: "Aceptar"
      })
      }else{
         authentication()
      } 
  
    }
  
    return (
      <div className='principal'>
        <Contenedorimagen2 className="contenedorImagen">
          <header>
            <p className="mensaje">
              ¡BIENVENIDO AL SISTEMA DE RESERVA DE SALAS
            </p>
            <div className="contenedor-imagen">
              <img src={logo} className="imagen" alt="Logo OOC" />
            </div>
          </header>
          <main>
            <h3>Inicio de Sesión</h3>
            <FontAwesomeIcon className="icono" icon={faUser} />
            <div className="contenedor-formulario">
              <Form className="formulario" onSubmit={handleSubmit}>
                <label className="texto">Correo Electrónico</label>

                <div className="contenedor-contenedor">
                  <span className="icono2">@</span>
                  <input
                    type="emai"
                    name='correo'
                    value={correo_Electronico}
                    placeholder="USUARIO"
                    onChange={async (e) => {
                      await setCorreo_Electronico(e.target.value);
                    }}
                  />
                </div>

                <label>Contraseña</label>
                <div className="contenedor-contenedor">
                  <span className="icono2">
                    <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                  </span>
                  <input
                    type="password"
                    value={contraseña}
                    placeholder="CONTRASEÑA"
                    onChange={async (e) => {
                      await setContraseña(e.target.value);
                    }}
                  />
                </div>
                {mostrar ? (
                    <h1
                      style={{ fontSize: "16px", color: "red" }}
                      type="invalid"
                    >
                      USUARIO O CONTRASEÑA INCORRECTA.
                    </h1>
                  ) : null}

                <div className='contenedor-boton'>
                <button className='boton' type='submit'>Iniciar Sesión</button>             
                </div>
                
                <footer>
                  <NavLink to="/formulario">¿No tienes una cuenta? Regístrate</NavLink>
                
                </footer>
              </Form>
            </div>


        
          </main>
        </Contenedorimagen2>
      </div>
    );
}

export default Login
