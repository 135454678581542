import React from 'react'
import Card from './Card'
import Navbar2 from './Navbar'
import democracia from '../assets/DSCF1602.jpg'
import etnias from '../assets/DSCF1688.jpg'


const cards=[
    {
        id:18,
        Nombre:'Las 9 Etnias',
        Edificio:'Plaza',
        N_piso:'No Aplica',
        Tamaño:'Grande',
        N_personas:'No Aplica',
        Estado:'Disponible',
        img:etnias,
        img2:etnias
    },
    {
        id:19,
        Nombre:'La Democracia',
        Edificio:'Plaza',
        N_piso:'No Aplica',
        Tamaño:'Grande',
        N_personas:'No Aplica',
        Estado:'Disponible',
        img:democracia,
        img2:democracia
    }
]

function Plazas() {
    return (
        <div>
            <Navbar2/>
        <div className="container d-flex flex-wrap justify-content-evenly ">
                    {
                        cards.map(card=>(
                        <div className="col-md-4 p-2" key={card.id} style={{width:"500px"}} >
                            <Card id={card.id} Nombre={card.Nombre} Edificio={card.Edificio} N_piso={card.N_piso} Tamaño={card.Tamaño} N_personas={card.N_personas} Estado={card.Estado} img={card.img} img2={card.img2} ></Card>
                        </div>
                        ))
                    }  
                </div>    
        </div>
    )
}

export default Plazas
