import {React,useState,useEffect,forwardRef} from 'react'
import MaterialTable from '@material-table/core'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Print from '@material-ui/icons/Print';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {Delete } from '@material-ui/icons'
import Message from '@material-ui/icons/Message';
import '../App.css'
import swal from 'sweetalert';
import Cookies from 'universal-cookie/es6';
import Navbar2 from './Navbar'
import {Redirect,useHistory} from 'react-router-dom'
import {Modal,Button,Table,FormGroup,FormLabel,FormControl, FormSelect} from "react-bootstrap";

const api=process.env.REACT_APP_API;

function Solicitudes() {

    let History=useHistory()
    const [solicitudes, setSolicitudes] = useState([])
    const [solicitudes2, setSolicitudes2] = useState([])
    const [solicitudes3, setSolicitudes3] = useState([])
    const [lgShow1,setLgShow1]=useState(false)
    const [lgShow2,setLgShow2]=useState(false)
    const [lgShow3,setLgShow3]=useState(false)
    const [nombre,setNombre]=useState("")
    const [correo,setCorreo]=useState("")
    const [cambio,setCambio]=useState(false)
    const [correoSelect,setcorreoSelect,]=useState("")
    const cookies=new Cookies();
    const rol=cookies.get("nombre_Rol");


    const getSalas = async () => {
        const res = await fetch(`${api}/solicitudes`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                rol
            })
        })                      

        const data = await res.json();
        data.map((result)=>{
            result.id_Solicitud="RS"+result.id_Solicitud;
            result.dia=(result.dia).slice(0,10);
            return result})
        setSolicitudes(data)
    }



    const mantenimiento = async () => {
        const res = await fetch(`${api}/mantenimiento`, {
        })                      

        const data = await res.json();
      
        setSolicitudes2(data)

        setLgShow2(true)
    }

    const mostrarF=()=>{
        setLgShow2(false)
        setLgShow1(true)   
    }

    const Agregar=async()=>{
        if(nombre==="" || correo===""){
            swal({
                title: "Validación de Datos",
                text: "Todos los Campos Son Obligatorios",
                icon: "warning",
                button: "Aceptar"
            })
        }else{
            const res = await fetch(`${api}/agregarMantenimiento`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                nombre,
                correo
                })
            })

            const data = await res.json();

            if(data[0]==="Agregado"){
                swal({
                    title: "Registro Guardado",
                    text: "Se Guardo Correctamente el resgistro",
                    icon: "success",
                    button: "Aceptar"
                })
                setLgShow1(false);
                setLgShow2(false);
                setNombre("")
                setCorreo("")

            }else if(data[0]==="Noagregado"){
                swal({
                    title: "Error al Guardar Registro",
                    text: "Ocurrio Un Error al Intentar Guardar el Registro",
                    icon: "error",
                    button: "Aceptar"
                })
                setLgShow1(false);
                setLgShow2(false);

            }
        }
     
    }


    const mostrarE=async()=>{
        setLgShow2(false)
        setLgShow3(true)   

        const res = await fetch(`${api}/mantenimiento`, {
        })                      

        const data = await res.json();
      
        setSolicitudes3(data)

    }

    const Eliminar=async()=>{
        if(correoSelect==="" || correoSelect==="Seleccione Una Opción"){
            swal({
                title: "Validación de Datos",
                text: "No seleccionó Registro",
                icon: "warning",
                button: "Aceptar"
            })
        }else{
            const res = await fetch(`${api}/eliminarMantenimiento`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                correoSelect
                })
            })

            const data = await res.json();

            if(data[0]==="Agregado"){
                swal({
                    title: "Registro Eliminado",
                    text: "Se Eliminó Correctamente el registro",
                    icon: "success",
                    button: "Aceptar"
                })

                setLgShow3(false)
                
            }else if(data[0]==="Noagregado"){
                swal({
                    title: "Error al Eliminar el Registro",
                    text: "Ocurrio Un Error al Intentar Guardar el Registro",
                    icon: "error",
                    button: "Aceptar"
                })
                setLgShow3(false)
            }

        }
    }

    useEffect(() => {
        getSalas();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cambio])

    const tableIcons = {
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        Print: forwardRef((props, ref) => <Print {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Message: forwardRef((props, ref) => <Message {...props} ref={ref} />)
    }

    const columns = [
        { title: "Código de Solcitud", field: "id_Solicitud" },
        { title: "Edificio", field: "edificio" },
        { title: "Nombre de Sala", field: "nombre_Sala" },
        { title: "N° de piso", field: "n_Piso" },
        { title: "Tamaño de Sala", field: "Tamaño_sala" },
        { title: "Día", field: "dia" },
        { title: "Hora de Inicio", field: "hora_Inicio" },
        { title: "Hora de Finalización", field: "hora_Fin" },
        { title: "Estado", field: "confirmacion" },
        { title: "Usuario", field: "correo_Electronico" },
    ];

    if(cookies.get("nombre_Rol")==="Usuario"){
        return <Redirect to="/inicio"></Redirect>
    }else{

return (

    <div style={{background:"white"}}>
        <Navbar2/>
 
    <div className='table-responsive'>
        <MaterialTable
            options={{
                actionsColumnIndex: -1, headerStyle: {
                    backgroundColor: '#4484ce',
                    color: '#FFF'
                },
                maxBodyHeight: '600px'
            }}
            icons={tableIcons}
            data={solicitudes}
            columns={columns}
            title="Solicitud de Reserva de Salas"
            actions={[{
                icon: Check,
                tooltip: 'Confirmar Solicitud',
                onClick: async (event, rowdata) => {
                    const confirmar = "Aceptada";
                    const id = rowdata.id_Solicitud;
                    const edificio = rowdata.edificio;
                    const nombre = rowdata.nombre_Sala;
                    const dia = rowdata.dia;
                    const inicio = rowdata.hora_Inicio;
                    const fin = rowdata.hora_Fin;
                    const correo_Electronico=rowdata.correo_Electronico
                    
                    
                    const res = await fetch(`${api}/consultarSala`, {
                        method: 'put',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          rol,
                          edificio,
                          nombre,
                          dia,
                          inicio,
                          fin
                        })
                    })                      
            
                    const data = await res.json();
                    const mostrar=data[0]
                   console.log(data)
           
                    if(mostrar.length===0){
                        swal({
                            text: 'Ingrese Comentario de Aceptación de Solicitud',
                            content: "input",
                            button: {
                              text: "Agregar",
                              closeModal: false,
                            },
                          }).then(async comentario=>{
                          
                            if(comentario===""){
                               
                                swal({
                                    title: "Estado de Solicitud de Reserva",
                                    text: "No se Realizó el Cambio en la Solicitud porque No se Agregó Comentario",
                                    icon: "error",
                                    button: "Aceptar"
                                })
                            
                            }else{
                                const res = await  fetch(`${api}/confirmar`, {
                                    method: 'delete',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        confirmar,
                                        id,
                                        edificio,
                                        nombre,
                                        dia,
                                        inicio,
                                        fin,
                                        comentario,
                                        correo_Electronico
                                    })
                                })
                                
                                const data2 = await res.json();
                                if(data2[0]==="vacio")
                                {
                                    swal({
                                        title: "Estado de Solicitud de Reserva",
                                        text: "No se Realizó el Cambio en la Solicitud porque No se Agregó Comentario",
                                        icon: "error",
                                        button: "Aceptar"
                                    })

                                }else if(data2[0]==="hecho"){
                                    swal({
                                        title: "Estado de Solicitud de Reserva",
                                        text: "Se Realizó el Cambio de Estado de la Solicitud de Reserva por Aceptada",
                                        icon: "success",
                                        button: "Aceptar"
                                    }).then(()=>{
                                        if(cookies.get("correo_Electronico")==="undefined"){
                                            History.push("./");
                                          }else{
                                           
                                            setCambio(!cambio)
                                          }
                                       
                                    })  
                                }
                              
                                
                            }             
                        });                            
                          
                    } else if(edificio==="Torre 2" && nombre==="Zona de Descarga" && mostrar.length<3){
                      
                        swal({
                            text: 'Ingrese Comentario de Aceptación de Solicitud',
                            content: "input",
                            button: {
                              text: "Agregar",
                              closeModal: false,
                            },
                          }).then(async comentario=>{
                          
                            if(comentario===""){
                               
                                swal({
                                    title: "Estado de Solicitud de Reserva",
                                    text: "No se Reealizó el Cambio en la Solicitud porque No se Agregó Comentario",
                                    icon: "error",
                                    button: "Aceptar"
                                })
                            
                            }else{
                                const res = await  fetch(`${api}/confirmar`, {
                                    method: 'delete',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        confirmar,
                                        id,
                                        edificio,
                                        nombre,
                                        dia,
                                        inicio,
                                        fin,
                                        comentario,
                                        correo_Electronico
                                    })
                                })
                                
                                const data2 = await res.json();
                                if(data2[0]==="vacio")
                                {
                                    swal({
                                        title: "Estado de Solicitud de Reserva",
                                        text: "No se Realizó el Cambio en la Solicitud porque No se Agregó Comentario",
                                        icon: "error",
                                        button: "Aceptar"
                                    })

                                }else if(data2[0]==="hecho"){
                                    swal({
                                        title: "Estado de Solicitud de Reserva",
                                        text: "Se Realizó el Cambio de Estado de la Solicitud de Reserva por Aceptada",
                                        icon: "success",
                                        button: "Aceptar"
                                    }).then(()=>{
                                        if(cookies.get("correo_Electronico")==="undefined"){
                                            History.push("./");
                                          }else{
                                           
                                            setCambio(!cambio)
                                          }
                                       
                                    })  
                                }
                              
                                
                            }             
                        }); 
                      
                    }
                    
                    
                    
                    
                    else{
                        const rechazada="Rechazada"
                        const comentario="No se Pudo Aceptar la Solciitud porque ya Existe Una Reserva que Crea Conflicto"
                        fetch(`${api}/rechazar`, {
                            method: 'delete',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                rechazada,
                                id,
                                edificio,
                                nombre,
                                dia,
                                inicio,
                                fin,
                                comentario,
                                correo_Electronico
                            })
                        })    
                        
                        swal({
                            title: "Solicitud de Reserva Rechazada",
                            text: "No se Puede Aceptar Porque ya Existe Una Reserva que Crea Conflicto",
                            icon: "error",
                            button: "Aceptar"
                        }).then(()=>{
                            if(cookies.get("correo_Electronico")==="undefined"){
                                History.push("./");
                              }else{
                                setCambio(!cambio)
                              }
                           
                        })
                    }
          
                }
            }, {
                icon: Delete,
                tooltip: 'Rechazar Solicitud',
                onClick: (event, rowdata) => {
                    const rechazada = "Rechazada";
                    const id = rowdata.id_Solicitud;
                    const edificio = rowdata.edificio;
                    const nombre = rowdata.nombre_Sala;
                    const dia = rowdata.dia;
                    const inicio = rowdata.hora_Inicio;
                    const fin = rowdata.hora_Fin;
                    const correo_Electronico=rowdata.correo_Electronico

                    swal({
                        text: 'Ingrese Comentario de Rechazo de Solicitud',
                        content: "input",
                        button: {
                          text: "Agregar",
                          closeModal: false,
                        },
                      }).then(async comentario=>{

                        if(comentario===""){
                            swal({
                                title: "Estado de Solicitud de Reserva",
                                text: "No se Reealizó el Cambio en la Solicitud porque No se Agregó Comentario",
                                icon: "error",
                                button: "Aceptar"
                            })
                        
                        }else{

                            const res =  await fetch(`${api}/rechazar`, {
                                method: 'delete',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    rechazada,
                                    id,
                                    edificio,
                                    nombre,
                                    dia,
                                    inicio,
                                    fin,
                                    comentario,
                                    correo_Electronico
                                })
                            })

                            const data2 = await res.json();
                            if(data2[0]==="vacio")
                                {
                                    swal({
                                        title: "Estado de Solicitud de Reserva",
                                        text: "No se Reealizó el Cambio en la Solicitud porque No se Agregó Comentario",
                                        icon: "error",
                                        button: "Aceptar"
                                    })
                               
                            }else{
                               

                                swal({
                                    title: "Estado de Solicitud de Reserva",
                                    text: "Se Realizó el Cambio de Estado de la Solicitud de Reserva por Rechazada",
                                    icon: "success",
                                    button: "Aceptar"
                                }).then(()=>{
                                    if(cookies.get("correo_Electronico")==="undefined"){
                                        History.push("./");
                                      }else{
                                       
                                        setCambio(!cambio)
                                      }
                              })
                            }    
                        }
                                              
                      })
                      
                }
            },{
                icon: Message,
                tooltip: 'Motivo de Visita',
                onClick: async (event, rowdata) => {
                    const codigoSC=rowdata.id_Solicitud
                    const res = await fetch(`${api}/verMotivo`, {
                      method: 'put',
                      headers: {
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({ 
                         codigoSC
                      })
                      
                  }) 
                  const data2 = await res.json();
                
                  const comen=data2.map(d=>d.comentario);
               
                  swal({
                    title: "Motivo de Visita",
                    text: `${comen}`,
                    icon: "info",
                    button: "Aceptar",
                  });
                  
                  }
            }]}
            localization={{
                pagination: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsPerPage: "resultados por página",
                    firstAriaLabel: "Primera página",
                    firstTooltip: "Primera página",
                    previousAriaLabel: "página anterior",
                    previousTooltip: "página anterior",
                    nextAriaLabel: "Siguiente página",
                    nextTooltip: "Siguiente página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                    labelRowsSelect: "Filas"
                },
                toolbar: {
                    nRowsSelected: '{0} row(s) selected'
                },
                header: {
                    actions: 'Acción'
                },
                body: {
                    emptyDataSourceMessage: 'No se encontraron registros',
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}
        >

        </MaterialTable>
    </div>
    <div className='container mt-1  mb-0 d-flex justify-content-center'> 
        <Button variant='info' className='p-1' onClick={mantenimiento}>Correo Mantenimiento</Button>
    </div>

    <Modal  size="lg"
            show={lgShow2}
            onHide={() => setLgShow2(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            className="d-flex justify-content-center align-items-center"
          >
            <Modal.Header closeButton className="App">
              <Modal.Title id="example-modal-sizes-title-lg">
                {" "}
                Consultar Lista Mantenimiento
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="container">
                <Table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Correo Electrónico</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                          solicitudes2.map(lista=>(
                            <tr>
                                <td key={lista.nombre_Correo}>{lista.nombre_Correo}</td>
                                <td key={lista.corre
                                }>{lista.corre}</td>
                            </tr>
                          ))
                        }
                    </tbody>
                </Table>
              </div>

              <div className='container d-flex justify-content-around'>
                    <Button variant='success' onClick={mostrarF}>Agregar</Button>
                    <Button variant='danger' onClick={mostrarE}>Eliminar</Button>
              </div>

            </Modal.Body>
          </Modal>


          <Modal  size="lg"
            show={lgShow1}
            onHide={() => setLgShow1(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            className="d-flex justify-content-center align-items-center">
            <Modal.Header closeButton className="App">
              <Modal.Title id="example-modal-sizes-title-lg">
                {" "}
                Agregar Registro Mantenimiento
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
                <div className='p-2'>
                    <FormGroup>
                        <FormLabel>Nombre</FormLabel>
                        <FormControl type="text" value={nombre} onChange={e => setNombre(e.target.value)}/>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Correo Electrónico</FormLabel>
                        <FormControl type="text" value={correo} onChange={e => setCorreo(e.target.value)}/>
                    </FormGroup>
                </div>
              <div className='container d-flex justify-content-center'>
                    <Button variant='success' onClick={Agregar}>Guardar</Button> 
              </div>
            </Modal.Body>
          </Modal>


          <Modal  size="lg"
            show={lgShow3}
            onHide={() => setLgShow3(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            className="d-flex justify-content-center align-items-center">
            <Modal.Header closeButton className="App">
              <Modal.Title id="example-modal-sizes-title-lg">
                {" "}
                Eliminar Registro Mantenimiento
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
                <div className='p-2'>
                    <FormGroup>
                        <FormLabel>Correo Electrónico</FormLabel>
                        <FormSelect value={correoSelect} onChange={e => setcorreoSelect(e.target.value)}>
                            <option>Seleccione Una Opción</option>
                            {
                                
                                 solicitudes3.map(lista=>(
                                    <option key={lista.corre}>{lista.corre}</option>
                                  ))
                            }    
                        </FormSelect> 
                    </FormGroup>
                </div>
              <div className='container d-flex justify-content-center'>
                    <Button variant='danger' onClick={Eliminar}>Eliminar</Button> 
              </div>
            </Modal.Body>
          </Modal>


    </div>
    )
        }

}

export default Solicitudes
