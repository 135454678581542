import { React,useState} from 'react'
import {Modal,Button} from "react-bootstrap";
import "../Appv2"
import swal from 'sweetalert';
import Cookies from 'universal-cookie/es6';
import { Restaurant } from '@material-ui/icons';


const api=process.env.REACT_APP_API;

function Card({ id, Nombre, Edificio, N_piso, Tamaño, N_personas, Estado, img, img2 }) {
    const [lgShow, setLgShow] = useState(false);
    const cookies=new Cookies();
    const correo_Electronico=cookies.get("correo_Electronico")
    const nombrePersona=cookies.get("nombre")
    const apellidoPersona=cookies.get("apellido")
    
    const nombreUsuario=cookies.get("nombre_Usuario")
    const [codigo] = useState(id)
    const [dia, setDia] = useState("")
    const [inicio, setInicio] = useState("")
    const [fin, setFin] = useState("")
    const [estado] = useState("Pendiente")
    const [edificio]=useState(Edificio)
    const [nombre]= useState(Nombre)
    const [fecha,setFecha]= useState("")

    
    

const handleSubmit=()=>{
  handleSubmit1().then(response=>{handleSubmit2(response)}).then(message())
}



    const handleSubmit1 = async (e) => {
          
      
      const res= await fetch(`${api}/agregar`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                dia,
                inicio,
                fin,
                estado,
                codigo,
                correo_Electronico
            })

        });

        const data=await res.json();

        return  data;        
    }

    const handleSubmit2 =async (response) => {
        await fetch(`${api}/correo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                edificio,
                nombre,
                dia,
                inicio,
                fin,
                correo_Electronico,
                N_piso,
                nombreUsuario,
                response,
                nombrePersona,
                apellidoPersona
            })
        })
    }

    const message = () => {
    swal({
        title: "Solicitud de Reserva de Salas Creadas",
        text: "Solicitud de Reserva Enviada. Se le Notificará Mediante Correo Electrónico si su Reserva es Confirmada/Rechazada",
        icon: "success",
        button: "Aceptar",
    });
    setDia("");
    setInicio("");
    setFin("");
    };
    
    const Fecha=()=>{
        const fecha2=new Date()
        var año=fecha2.getFullYear();
        var mes= fecha2.getMonth()+1;
        var dia=fecha2.getDate();
        var hora=fecha2.getHours();
        var minutos=fecha2.getMinutes();
        
        if(hora<10){
            hora="0"+hora
        }

        if(minutos<10){
            minutos="0"+minutos
        }


        if(dia<10){
            dia="0"+dia
        }

        if(mes<10){
            mes="0"+mes
        }
        setFecha(año+"-"+mes+"-"+dia)
    }


    const validacion=(e)=>{
        e.preventDefault();
    /*    var day = diaActual.getDate();
        var month = diaActual.getMonth()+1;
        var year = diaActual.getFullYear();
        var hour =diaActual.getHours();
        dia.charAt(0)
        dia.charAt(1)
        dia.charAt(2)
        dia.charAt(3)
        dia.charAt(5)
        dia.charAt(6)
        dia.charAt(8)
        dia.charAt(9)
       var añoR=dia.charAt(0)+dia.charAt(1)+dia.charAt(2)+dia.charAt(3);
        var mesR=dia.charAt(5)+dia.charAt(6)
        var diaR=dia.charAt(8)+dia.charAt(9)

        if(month===mesR && year===añoR && day===diaR){
            var resta=12-parseInt(inicio)
            if(resta<=hour){
                swal({
                    title: "Error al Crear Solicitud",
                    text: "Para Poder Crear Una Solicitud de Sala se debe de hacer con 12 horas de Anticipación",
                    icon: "error",
                    button: "Aceptar"
                })
            }else{
                handleSubmit();
            }
            */
        if(dia==="" || inicio==="" || fin==="" ){
            swal({
                title: "Validacion de Datos",
                text: "Todos los Campos Deben de Estar Completo",
                icon: "warning",
                button: "Aceptar"
            })
        }
        else{
            handleSubmit()
        }       
    }


    return (
      <div className="card text-center bg-dark">
        <div className="card-body text-light">
          <img src={img} className="img-fluid" style={{backgroundColor:"white"}} alt="Imagen de sala" />
          <h4 className="card-title">{Nombre}</h4>
          <h5>Edificio: {Edificio}</h5>
          <p>N° de Piso: {N_piso} </p>
          <p>Tamaño: {Tamaño}</p>
          <p>N° de personas: {N_personas}</p>
          <p>Estado: {Estado}</p>
        </div>
        {
          Estado==="Disponible"?  <Button
          variant="primary"
          onClick={() => {
            Fecha();
            setLgShow(true);
          }}
        >
          Solicitud de Reserva
        </Button>:null
        }
      
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          className="d-flex justify-content-center align-items-center"
        >
          <Modal.Header closeButton className="App">
            <Modal.Title id="example-modal-sizes-title-lg">
              {" "}
              Solicitud de Reserva {Nombre}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="App">
            <div className="container text-center">
              <div className="row">
                <div className="col-md-6 text-light bg-dark p-4">
                  <h4 className="text-center">Crear Reservar</h4>
                  <form className="row text-start" onSubmit={validacion}>
                    <div className="p-2">
                      <label htmlFor="dia" className="form-label">
                        Día a Reservar
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="dia"
                        min={fecha}
                        onChange={(e) => {
                          setDia(e.target.value);
                        }}
                        value={dia}
                        autoFocus
                      />
                    </div>
                    <div className="p-2">
                      <label htmlFor="minutos" className="form-label">
                        Hora de Inicio
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="minutos"
                        min="08:00"
                        onChange={(e) => setInicio(e.target.value)}
                        value={inicio}
                      />
                    </div>

                    <div className="p-2">
                      <label htmlFor="fin" className="form-label">
                        Hora de Fin
                      </label>
                      <input
                        type="time"
                        className="form-control"
                        id="fin"
                        min={inicio}
                        onChange={(e) => setFin(e.target.value)}
                        value={fin}
                      />
                    </div>
                    <div className="p-2">
                      <button
                        type="submit"
                        className="btn btn-success form-control"
                      >
                        Crear Solicitud Reserva
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-6 text-light bg-dark p-4">
                  <img src={img2} className="img-fluid" alt="" />
                  <h5>Edificio: {Edificio}</h5>
                  <p>N° de Piso: {N_piso} </p>
                  <p> Tamaño de Sala: {Tamaño}</p>
                  <p>N° de personas: {N_personas}</p>
                  <p>Estado: {Estado}</p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );


}

export default Card