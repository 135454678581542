import React from 'react';
import {Redirect} from 'react-router-dom'
import Cookies from 'universal-cookie/es6';

function PrivateRoute({component: Component,...rest}){
    const cookies = new Cookies();


    if(cookies.get("correo_Electronico")===undefined){
        return <Redirect to="/login"></Redirect>
    }else{
        return <Component></Component>
        
    }

  
}

export default PrivateRoute;
