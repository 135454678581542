import {React} from 'react'
import {Button,Form,Tooltip,OverlayTrigger,Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import {useHistory} from "react-router-dom";
import Cookies from 'universal-cookie/es6';

function PerfilUsuario() {
    const cookies=new Cookies();
  let History=useHistory();
      

    const correo_Electronico=cookies.get("correo_Electronico")
    const nombre=cookies.get("nombre")
    const apellido=cookies.get("apellido")
    const nombre_Usuario=cookies.get("nombre_Usuario")
    const telefono=cookies.get("telefono")
    const Nombre_Edificio=cookies.get("Nombre_Edificio")
    const nombre_Institucion=cookies.get("nombre_Institucion")
    const nombre_Are=cookies.get("nombre_Are")

  

  

    const cerrarSesion=()=>{
      cookies.remove('correo_Electronico',{path:"/"})
      cookies.remove('nombre',{path:"/"})
      cookies.remove('apellido',{path:"/"})
      cookies.remove('nombre_Usuario',{path:"/"})
      cookies.remove('Nombre_Edificio',{path:"/"})
      cookies.remove('nombre_Institucion',{path:"/"})
      cookies.remove('nombre_Are',{path:"/"})
      History.push("./")
    }
 
    const editar = () => {
      History.push("./editar")
    };
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Nombre con el Cual se le Identificará en el Sistema
      </Tooltip>
    );

    const renderTooltip2 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Correo Electrónico al que Recibirá Información Relacionada a sus
        Solicitudes
      </Tooltip>
    );

    const renderTooltip3 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Cerrar Sesión
      </Tooltip>
    );

    return (
      <div>
       
        <Form bg="dark" variant="dark">
          <Form.Group className="mb-2" controlId="controlInput1">
            <Form.Label className='m-0'>Nombre</Form.Label>
            <Form.Control type="text" placeholder="Juan" disabled value={nombre} />
          </Form.Group>

          <Form.Group className="mb-2" controlId="controlInput2">
            <Form.Label className='m-0'>Apellido</Form.Label>
            <Form.Control type="text" placeholder="López" disabled value={apellido} />
          </Form.Group>

          <Form.Group className="mb-2" controlId="controlInput3">
            <Form.Label className='m-0'>
              Nombre de Usuario
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Badge bg="secondary">?</Badge>
              </OverlayTrigger>
            </Form.Label>

            <Form.Control
              type="text"
              placeholder="Juan López"
              disabled
              value={nombre_Usuario}
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="controlInput4">
            <Form.Label className='m-0'>
              Correo Electrónico
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip2}
              >
                <Badge bg="secondary">?</Badge>
              </OverlayTrigger>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="juanlopez@gmail.com"
              disabled
              value={correo_Electronico}
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="controlInput4">
            <Form.Label className='m-0'>Teléfono</Form.Label>
            <Form.Control
              type="text"
              placeholder="####-####"
              disabled
              value={telefono}
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="controlInput4">
            <Form.Label className='m-0 '>Edificio</Form.Label>
            <Form.Control
              value={Nombre_Edificio}
              disabled
              aria-label="Seleccione una Opcion"
              onChange={async (e) => {
                }}
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="controlInput4">
            <Form.Label className='m-0'>Institución</Form.Label>
            <Form.Control aria-label="Seleccione una Opcion"  disabled value={nombre_Institucion} onChange={e => {}} />
          </Form.Group>

          <Form.Group className="mb-2" controlId="controlInput4">
            <Form.Label className='m-0'>Área de Trabajo (Departamento)</Form.Label>
            <Form.Control aria-label="Seleccione una Opcion" disabled value={nombre_Are}  onChange={e => {}} />
          </Form.Group>

          <Form.Group className="mb-2 text-center" controlId="controlInput4">
            <Button variant="warning" onClick={editar}>
                Editar Información
            </Button>
          </Form.Group>
        </Form>

        <div className='text-end'>
        <Button variant='danger' onClick={cerrarSesion}>
          <OverlayTrigger placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip3}>
                  <Badge bg="danger"><FontAwesomeIcon icon={faSignOutAlt}></FontAwesomeIcon></Badge>

          </OverlayTrigger>
        </Button>
        </div>
      </div>
    );
}

export default PerfilUsuario
