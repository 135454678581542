import { React,useState} from 'react'
import {Modal,Button} from "react-bootstrap";
import "../Appv2"
import swal from 'sweetalert';
import Cookies from 'universal-cookie/es6';

const api=process.env.REACT_APP_API;


function Data({id,Nombre,Edificio,N_piso,Tamaño,N_personas,Estado,img,img2}) {
    const cookies=new Cookies();
    const correo_Electronico=cookies.get("correo_Electronico")
    const nombreUsuario=cookies.get("nombre_Usuario")
    const nombrePersona=cookies.get("nombre")
    const apellidoPersona=cookies.get("apellido")

    const [lgShow, setLgShow] = useState(false);
    const [dia,setDia]=useState("");
    const [inicio,setInicio]=useState("");
    const [fin,setFin]=useState("");
    const [estado] = useState("Pendiente")
    const [codigo] = useState(id)
    const [usuario] = useState(1)
    const [edificio]=useState(Edificio)
    const [nombre]= useState(Nombre)
    const [motivoV,setMotivoV]= useState("")
    const [fecha,setFecha]= useState("")
    


const handleSubmit=async()=>{
    const res= await fetch(`${api}/agregar`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            dia,
            inicio,
            fin,
            estado,
            codigo,
            usuario,
            correo_Electronico,
            motivoV
        })
    })

    const data=await res.json()

    return data
}


    const handleSubmit1 = async (e) => {
        e.preventDefault();
        if(dia==="" || inicio==="" || fin==="" || motivoV==="" ){
            swal({
                title: "Validacion de Datos",
                text: "Todos los campos deben de estar llenos",
                icon: "warning",
                button: "Aceptar"
            })
        }else{
            handleSubmit().then(response=>handleSubmit2(response)).then(message())

         
    }
    }

    const handleSubmit2 =(response) => {
        fetch(`${api}/correo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                edificio,
                nombre,
                dia,
                inicio,
                fin,
                correo_Electronico,
                N_piso,
                nombreUsuario,
                response,
                nombrePersona,
                apellidoPersona
            })
        })
    }

    const message = () => {
    swal({
        title: "Solicitud de Reserva de Salas Creadas",
        text: "Solicitud de Reserva Enviada. Se le Notificará Mediante Correo Electrónico si su Reserva es Confirmada/Rechazada",
        icon: "success",
        button: "Aceptar",
    });
    setDia("");
    setInicio("");
    setFin("");
    };

      const Fecha=()=>{
        const fecha2=new Date()
        var año=fecha2.getFullYear();
        var mes= fecha2.getMonth()+1;
        var dia=fecha2.getDate();

        
        if(dia<10){
            dia="0"+dia
        }

        if(mes<10){
            mes="0"+mes
        }
        setFecha(año+"-"+mes+"-"+dia)
    }
   /* const prueba=()=>{
        var diaActual = new Date();
        var day = diaActual.getDate()+1;
        var month = diaActual.getMonth()+1;
        
        var year = diaActual.getFullYear();
        var hour2 =diaActual.getHours();
        var minutos= diaActual.getMinutes();
       
        if(month>10){
            setMes(month)
        }else if(month<10){
            setMes("0"+month)
            
        }
        var fecha=year+"-"+mes+"-"+day
       
        if(day>10){
            setDiaL(fecha)
        }else if(day<10){
            setDiaL(year+"-"+mes+"-"+"0"+day)
        }

        if(minutos>10 && hour2>10){
            setHoraL(hour2+":"+minutos)
        }else if(minutos<10 && hour2<0){
            setHoraL("0"+hour2+":"+"0"+minutos)
        }else if(minutos>10 && hour2<10){
            setHoraL("0"+hour2+":"+minutos)
            
        }else if(minutos<10 && hour2>10){
            setHoraL(hour2+":"+"0"+minutos)
        }
    }
        */


    return (
        <div className="card text-center bg-dark">
        <div className="card-body text-light">
            <img src={img} className="img-fluid" alt="" />
            <h4 className="card-title">{Nombre}</h4>
            <h5>Edificio: {Edificio}</h5>
            <p>N° de Piso: {N_piso} </p>
            <p>Tamaño: {Tamaño}</p>
            <p>N° de personas: {N_personas}</p>
            <p>Estado: {Estado}</p>
        </div>
        <Button variant="primary" onClick={() =>{Fecha(); setLgShow(true)}}>Solicitud de Reserva</Button>
        <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
        >
            <Modal.Header closeButton className="App">
                <Modal.Title id="example-modal-sizes-title-lg"> Solicitud de Reserva {Nombre}
                </Modal.Title>
            </Modal.Header >
            <Modal.Body className="App">
                <div className="container text-center">
                    <div className="row">
                        <div className="col-md-6 text-light bg-dark p-4" >
                            <h4 className="text-center">Crear Reservar</h4>
                            <form className="row text-start" onSubmit={handleSubmit1}>
                                <div className="p-2">
                                    <label htmlFor="dia" className="form-label" >Día a Reservar</label>
                                    <input type="date" className="form-control" id="dia" min={fecha} onChange={e => {setDia(e.target.value)}} value={dia} autoFocus />
                                </div>
                                <div className="p-2">
                                    <label htmlFor="minutos" className="form-label">Hora de Inicio</label>
                                    <input type="time" className="form-control" id="minutos" onChange={e => setInicio(e.target.value)} value={inicio}  />
                                </div>

                                <div className="p-2">
                                    <label htmlFor="fin" className="form-label">Hora de Fin</label>
                                    <input type="time" className="form-control" id="fin" min={inicio} onChange={e => setFin(e.target.value)} value={fin} />
                                </div>

                                <div className="p-2">
                                    <label htmlFor="motivo" className="form-label">Motivo de Visita</label>
                                    <input type="text" className="form-control" id="motivo" minLength="10" maxLength="40" onChange={e => setMotivoV(e.target.value)} value={motivoV} />
                                </div>

                                <div className="p-2">
                                    <button type="submit" className="btn btn-success form-control">Crear Solicitud Reserva</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 text-light bg-dark p-4">
                            <img src={img2} className="img-fluid" alt="" />
                            <h5 >Edificio: {Edificio}</h5>
                            <p>N° de Piso: {N_piso} </p>
                            <p> Tamaño de Sala: {Tamaño}</p>
                            <p>N° de personas: {N_personas}</p>
                            <p>Estado: {Estado}</p>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    </div>
    )
}

export default Data
