import {React,useState,useEffect,forwardRef} from 'react'
import MaterialTable from '@material-table/core'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Print from '@material-ui/icons/Print';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Message from '@material-ui/icons/Message';
import '../App.css';
import Cookies from 'universal-cookie/es6';
import Navbar2 from './Navbar'
import {Modal} from "react-bootstrap";

const api = process.env.REACT_APP_API;


function Visitas() {
    const cookies=new Cookies();
    const [solicitudes,setSolicitudes]=useState([])
    const [solicitudes2,setSolicitudes2]=useState([])
    const correo_Electronico=cookies.get("correo_Electronico")
    const [lgShow2, setLgShow2] = useState(false);

    const handleSubmit = async () => {
 
        const res = await fetch(`${api}/consultarDataAceptadas`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              correo_Electronico
            })
        })
  
        const data2 = await res.json();

        data2.map((result)=>{result.id_Solicitud="RS"+result.id_Solicitud; return result})
      
        setSolicitudes(data2);
    }

    useEffect(()=>{
        handleSubmit();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  

    const tableIcons = {
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        Print: forwardRef((props, ref) => <Print {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        Message: forwardRef((props, ref) => <Message {...props} ref={ref} />)
    }
    
    const columns = [
        { title: "Código de Solcitud", field: "id_Solicitud" },
        { title: "Edificio", field: "edificio" },
        { title: "Nombre de Sala", field: "nombre_Sala" },
        { title: "N° de piso", field: "n_Piso" },
        { title: "Tamaño de Sala", field: "Tamaño_sala" },
        { title: "Día", field: "dia" },
        { title: "Hora de Inicio", field: "hora_Inicio" },
        { title: "Hora de Finalización", field: "hora_Fin" },
        { title: "Estado", field: "confirmacion" },
        { title: "Usuario", field: "correo_Electronico" },
    ];

    const columns2 = [
      { title: "Código de Solicitud", field: "id_Solicitud" },
      { title: "Nombre de visita", field: "Nombre" },
      { title: "Número de identidad", field: "id" }
  ];
    

  
  return <div>
      <Navbar2/>
      
      <div className='table-responsive'>
      <MaterialTable
                 options={{
                  actionsColumnIndex: -1, headerStyle: {
                      backgroundColor: '#4484ce',
                      color: '#FFF'
                  },
                  maxBodyHeight: '600px'
              }}
                icons={tableIcons}
                data={solicitudes}
                columns={columns}
                title="Data Center"
                localization={{
                  pagination: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsPerPage: "resultados por página",
                    firstAriaLabel: "Primera página",
                    firstTooltip: "Primera página",
                    previousAriaLabel: "página anterior",
                    previousTooltip: "página anterior",
                    nextAriaLabel: "Siguiente página",
                    nextTooltip: "Siguiente página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    nRowsSelected: "{0} row(s) selected",
                  },
                  header: {
                    actions: "Acción",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                actions={[
                  {
                    icon: RemoveRedEye,
                    tooltip: "Ver Lista de Visitantes",
                    onClick: async (event, rowdata) => {
                        setLgShow2(true)
                        const id_Solicitudesd=rowdata.id_Solicitud;
                        const res = await fetch(`${api}/consultarVisitas`,{
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                             },
                            body: JSON.stringify({
                                id_Solicitudesd
                        })
                        })
                        const data = await res.json();
                        setSolicitudes2(data);
                    },
                  }
                ]}
              ></MaterialTable>
 <Modal
            size="lg"
            show={lgShow2}
            onHide={() => setLgShow2(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton className="App">
              <Modal.Title id="example-modal-sizes-title-lg">
                {" "}
                Consultar Datos de visitantes
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="container text-start">
                <MaterialTable
                title="Visitas"
                 columns={columns2}
                  data={solicitudes2}
                  icons={tableIcons}
                  options={{search: false}}
                  ></MaterialTable>
              </div>
            </Modal.Body>
          </Modal>
      </div>

  </div>
}

export default Visitas;
