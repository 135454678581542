import {React,useState,useEffect} from 'react';
import {Form,Button, FormGroup, FormLabel, FormControl, FormSelect} from "react-bootstrap";
import Cookies from 'universal-cookie/es6';
import swal from 'sweetalert';
import {useHistory} from "react-router-dom";
const api=process.env.REACT_APP_API;



function EditarInfo() {
    const cookies=new Cookies();
  let History=useHistory();
    

    const [area,setArea]=useState([]);


    const [narea,setNarea]=useState(cookies.get("nombre_Are"));
    const [nombre,setNombre]=useState(cookies.get("nombre"));
    const [apellido,setApellido]=useState(cookies.get("apellido"));
    const [nombreUsuario,setNombreUsuario]=useState(cookies.get("nombre_Usuario"));
    const [correo,setCorreo]=useState(cookies.get("correo_Electronico"));
    const [telefono,setTelefono]=useState(cookies.get("telefono"));
    const [edificio,setEdificio]=useState(cookies.get("Nombre_Edificio"));
    const [nombre_Institucion,setNombre_Institucion]=useState(cookies.get("nombre_Institucion"));
    const [marcado,setMarcado]=useState(false);
    const [botones,setBotones]=useState(true);

    const [contrasenaA,setContrasenaA]=useState("");
    const [contrasenaN,setContrasenaN]=useState("");


    const consultarArea = async () => {
        const res = await fetch(`${api}/area`);
        const data = await res.json();
        setArea(data);
      };

      const editarContra= async()=>{
          if(contrasenaA==="" || contrasenaN===""){
            swal({
                title: "Validacion de Datos",
                text: "Todos los campos deben de estar llenos",
                icon: "warning",
                button: "Aceptar"
              })
          }else{

            const res =await fetch(`${api}/editarContra`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    correo,
                    contrasenaA,
                    contrasenaN
                })
            })

            const data = await res.json();
            if(data[0]==="Si"){
                swal({
                    title: "Cambio de Contraseña Éxitoso",
                    text: "Tiene que Iniciar Sesión de Nuevo Utilizando la Nueva Contraseña",
                    icon: "success",
                    button: "Aceptar"
                  })
                  cookies.remove('correo_Electronico',{path:"/"})
                  cookies.remove('nombre',{path:"/"})
                  cookies.remove('apellido',{path:"/"})
                  cookies.remove('nombre_Usuario',{path:"/"})
                  cookies.remove('Nombre_Edificio',{path:"/"})
                  cookies.remove('nombre_Institucion',{path:"/"})
                  cookies.remove('nombre_Are',{path:"/"})
                  setTimeout(()=>History.push("./inicio"),3000);
                  
            }else{
                swal({
                    title: "No Se hizo el Cambio de Contraseña",
                    text: 'La contraseña Ingresada en el Campo "Contraseña Actual" No Coincide con la Contraseña Anterior',
                    icon: "error",
                    button: "Aceptar"
                  })
            }

          }
      }
    const Guardar=async(e)=>{
        e.preventDefault();

        if(nombre==="" || apellido==="" || nombreUsuario==="" || correo==="" || telefono==="" || edificio==="" || narea==="" || nombre_Institucion===""){
            swal({
                title: "Validacion de Datos",
                text: "Todos los campos deben de estar llenos",
                icon: "warning",
                button: "Aceptar"
              })
        }else{


        const res =await fetch(`${api}/editar`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nombre,
                apellido,
                nombreUsuario,
                correo,
                telefono,
                edificio,
                narea,
                nombre_Institucion
            })
        })

        const data = await res.json();
        if(data[0]==="Actualizado"){
            swal({
                title: "Actualización de Datos Exitosa",
                text: "Se Guardaron los Cambios Realizados en la Información del Usuario. Para Ver los Cambios en el Sistema Vuelva a Iniciar Sesión",
                icon: "success",
                button: "Aceptar",
            });
            setTimeout(()=>History.push("./inicio"),3000);
        }else if(data[0]==="No"){


        }
    }
    }  
  
      useEffect(() => {
        consultarArea()
      }, [])

  
  return (
    <div style={{height:"100vh"}}>
      <div className="container d-flex justify-content-center pt-3">
        <h3>Editar Información de Usuario</h3>
      </div>

      <div className="container pt-4 ">

          {botones ?
        <div className="d-flex ">
          <FormGroup
            className="offset-2 col-md-4"
            style={{ marginRight: "10px" }}
          >
            <FormLabel className="m-0">Nombre</FormLabel>
            <FormControl
              type="text"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="col-md-4">
            <FormLabel className="m-0">Apellido</FormLabel>
            <FormControl
              type="text"
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
            />
          </FormGroup>
        </div>
        :null}


        {botones ? 
        <div className="d-flex pt-3">
          <FormGroup
            className="offset-2 col-md-4"
            style={{ marginRight: "10px" }}
          >
            <FormLabel className="m-0">Nombre de Usuario</FormLabel>
            <FormControl
              type="text"
              value={nombreUsuario}
              onChange={(e) => setNombreUsuario(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="col-md-4">
            <FormLabel className="m-0">Correo Electrónico</FormLabel>
            <FormControl
              type="text"
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
              disabled
            />
          </FormGroup>
        </div>
        :null}


        {botones ? 
        <div className="d-flex pt-3">
          <FormGroup
            className="offset-2 col-md-4"
            style={{ marginRight: "10px" }}
          >
            <FormLabel className="m-0">Teléfono</FormLabel>
            <FormControl
              type="text"
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
            />
          </FormGroup>
          <FormGroup className="col-md-4">
            <FormLabel className="m-0">Edificio</FormLabel>
            <FormControl
              type="text"
              value={edificio}
              onChange={(e) => setEdificio(e.target.value)}
              disabled
            />
          </FormGroup>
        </div>
        :null}  

        {botones ? 

        <div className="d-flex pt-3">
          <FormGroup
            className="offset-2 col-md-4"
            style={{ marginRight: "10px" }}
          >
            <FormLabel className="m-0">Nombre de Institución</FormLabel>
            <FormControl
              type="text"
              value={nombre_Institucion}
              onChange={(e) => setNombre_Institucion(e.target.value)}
              disabled
            />
          </FormGroup>
          <FormGroup className="col-md-4">
            <FormLabel className="m-0">Area de Trabajo</FormLabel>
            <FormSelect
              type="text"
              value={narea}
              onChange={(e) => setNarea(e.target.value)}
            >
              {area.map((lista) => (
                <option key={lista.nombre_Are}>{lista.nombre_Are}</option>
              ))}
            </FormSelect>
          </FormGroup>
        </div>

        :null}  

        <div className="d-flex pt-3">
          <FormGroup className="offset-2">
            <Form.Check
              type="checkbox"
              label="Cambiar Contraseña"
              onClick={() => {
                setMarcado(!marcado);
                setBotones(!botones);
              }}
            />
          </FormGroup>
        </div>

        {marcado ? (
          <div className="d-flex pt-3">
            <FormGroup
              className="offset-2 col-md-4"
              style={{ marginRight: "10px" }}
            >
              <FormLabel className="m-0">Contraseña Actual</FormLabel>
              <FormControl
                type="password"
                value={contrasenaA}
                onChange={(e) => setContrasenaA(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="col-md-4">
              <FormLabel className="m-0">Nueva Contraseña</FormLabel>
              <FormControl
                type="password"
                value={contrasenaN}
                onChange={(e) => setContrasenaN(e.target.value)}
              />
            </FormGroup>
          </div>
        ) : null}

        {marcado ? (
          <div className="d-flex pt-3">
            <FormGroup className="offset-2 col-md-8">
              <Button className="offset-3 col-md-6" variant="warning"  onClick={editarContra}>
                Cambiar Contraseña
              </Button>
            </FormGroup>
          </div>
        ) : null}

        {botones ? (
          <div className="offset-2 col-md-4 pt-3">
            <FormGroup className="d-flex">
              <Button
                className="col-md-12"
                style={{ marginRight: "10px" }}
                variant="danger"
                onClick={() => (History.push("./inicio"))}
              >
                Cancelar cambios
              </Button>
              <Button className="col-md-12" variant="success" onClick={Guardar}>
                Guardar cambios
              </Button>
            </FormGroup>
          </div>
        ) : null}
      </div>
    </div>
  );
      
}

export default EditarInfo;
