import React from 'react'
import Card from './Card'
import Navbar2 from './Navbar'
import Cookies from 'universal-cookie/es6';
import {Redirect} from 'react-router-dom'
import sala1 from '../assets/cbbSala1.jpg'
import sala2 from '../assets/cbbSala2.jpg'
import sala3 from '../assets/cbbSala3.jpg'
import sala4 from '../assets/cbbSala4.jpg'
import sala5 from '../assets/cbbSala5A.jpg'
import comedor from '../assets/CBBComedorD.jpg'



const cards=[
    {
        id:8,
        Nombre:'Sala 1',
        Edificio:'CBB',
        N_piso:'5',
        Tamaño:'Grande',
        N_personas:'18',
        Estado:'Disponible',
        img:sala1,
        img2:sala1
    },
    {
        id:9,
        Nombre:'Sala 2',
        Edificio:'CBB',
        N_piso:'5',
        Tamaño:'Grande',
        N_personas:'18',
        Estado:'Disponible',
        img:sala2,
        img2:sala2
    },
    {
        id:10,
        Nombre:'Sala 3',
        Edificio:'CBB',
        N_piso:'5',
        Tamaño:'Mediana',
        N_personas:'12',
        Estado:'No Disponible',
        img:sala3,
        img2:sala3
    },
    {
        id:11,
        Nombre:'Sala 4',
        Edificio:'CBB',
        N_piso:'5',
        Tamaño:'Mediana',
        N_personas:'12',
        Estado:'No Disponible',
        img:sala4,
        img2:sala4
    },
    {
        id:12,
        Nombre:'Sala 5',
        Edificio:'CBB',
        N_piso:'5',
        Tamaño:'Mediana',
        N_personas:'12',
        Estado:'No Disponible',
        img:sala5,
        img2:sala5
    },
    {
        id:30,
        Nombre:'Comedor',
        Edificio:'CBB',
        N_piso:'5',
        Tamaño:'Grande',
        N_personas:'80',
        Estado:'Disponible',
        img:comedor,
        img2:comedor
    }

]



function Cards3() {
   return (
           <div>
           <Navbar2/>
            <div className="container d-flex flex-wrap">
            
                 {         
                  cards.map(card=>(
                    <div className="col-md-4 p-2" key={card.id}>
                    <Card id={card.id} Nombre={card.Nombre} Edificio={card.Edificio} N_piso={card.N_piso} Tamaño={card.Tamaño} N_personas={card.N_personas} Estado={card.Estado} img={card.img} img2={card.img2} ></Card>
                    {
                    }
                    </div>
                  ))
              }        
           
           
        </div>
     
        </div>
    )
            }

export default Cards3
