import React from 'react'
import Card from './Card'
import Navbar2 from './Navbar'
import Cookies from 'universal-cookie/es6';
import {Redirect} from 'react-router-dom'
import sala1 from '../assets/t2Sala1.jpg'
import sala2 from '../assets/t2Sala2.jpg'
import sala3 from '../assets/t2Sala3.jpg'
import sala4 from '../assets/t2Sala4.jpg'
import Comedor from '../assets/t2Comedor.jpg'



const cards=[
    {
        id:4,
        Nombre:'Sala 1',
        Edificio:'Torre 2',
        N_piso:'12',
        Tamaño:'Grande',
        N_personas:'18',
        Estado:'No Disponible',
        img:sala1,
        img2:sala1
    },
    {
        id:5,
        Nombre:'Sala 2',
        Edificio:'Torre 2',
        N_piso:'12',
        Tamaño:'Mediana',
        N_personas:'12',
        Estado:'Disponible',
        img:sala2,
        img2:sala2
    },
    {
        id:6,
        Nombre:'Sala 3',
        Edificio:'Torre 2',
        N_piso:'12',
        Tamaño:'Mediana',
        N_personas:'12',
        Estado:'Disponible',
        img:sala3,
        img2:sala3
    },    {
        id:7,
        Nombre:'Sala 4',
        Edificio:'Torre 2',
        N_piso:'12',
        Tamaño:'Pequeña',
        N_personas:'4',
        Estado:'No Disponible',
        img:sala4,
        img2:sala4
    },

    {
        id:29,
        Nombre:'Comedor',
        Edificio:'Torre 2',
        N_piso:'13',
        Tamaño:'Grande',
        N_personas:'90',
        Estado:'Disponible',
        img:Comedor,
        img2:Comedor
    
    }

]


function Card2() {

    return (
    
        <div>
            <Navbar2/>
 
            <div className="container d-flex flex-wrap">
              {
                  cards.map(card=>(
                    <div className="col-md-4 p-2" key={card.id}>
                    <Card id={card.id} Nombre={card.Nombre} Edificio={card.Edificio} N_piso={card.N_piso} Tamaño={card.Tamaño} N_personas={card.N_personas} Estado={card.Estado} img={card.img} img2={card.img2} ></Card>
                    </div>
                  ))
              }  
              
            </div>    

            
            </div>            
               
    )
            }


export default Card2
