import React from "react";
import Card from "./Card";
import Navbar2 from "./Navbar";
import Cookies from "universal-cookie/es6";
import { Redirect } from "react-router-dom";
import sala1 from "../assets/t1Sala1.jpg";
import sala2 from "../assets/t1Sala2.jpg";
import sala3 from "../assets/t1Sala3.jpg";
import sala4 from "../assets/t1Sala4.jpg";
import Comedor from "../assets/t1Comedor.jpg";

const cards = [
  {
    id: 1,
    Nombre: "Sala 1",
    Edificio: "Torre1",
    N_piso: "10",
    Tamaño: "Mediana",
    N_personas: "12",
    Estado: "No Disponible",
    img: sala1,
    img2: sala1,
  },
  {
    id: 2,
    Nombre: "Sala 2",
    Edificio: "Torre1",
    N_piso: "10",
    Tamaño: "Mediana",
    N_personas: "12",
    Estado: "No Disponible",
    img: sala2,
    img2: sala2,
  },
  {
    id: 33,
    Nombre: "Sala 3",
    Edificio: "Torre1",
    N_piso: "10",
    Tamaño: "Mediana",
    N_personas: "10",
    Estado: "Disponible",
    img: sala3,
    img2: sala3,
  },
  {
    id: 32,
    Nombre: "Sala 4",
    Edificio: "Torre1",
    N_piso: "10",
    Tamaño: "Grande",
    N_personas: "18",
    Estado: "Disponible",
    img: sala4,
    img2: sala4,
  },  {
    id: 34,
    Nombre: "Sala 1",
    Edificio: "Torre 1",
    N_piso: "18",
    Tamaño: "Grande",
    N_personas: "24",
    Estado: "Disponible",
    img: sala1,
    img2: sala1,
  },
  
  {
    id: 35,
    Nombre: "Sala 2",
    Edificio: "Torre 1",
    N_piso: "18",
    Tamaño: "Grande",
    N_personas: "24",
    Estado: "Disponible",
    img: sala1,
    img2: sala1,
  }
  ,{
    id: 36,
    Nombre: "Sala 3",
    Edificio: "Torre 1",
    N_piso: "18",
    Tamaño: "Grande",
    N_personas: "24",
    Estado: "Disponible",
    img: sala1,
    img2: sala1,
  },
  {
    id: 28,
    Nombre: "Comedor",
    Edificio: "Torre1",
    N_piso: "17",
    Tamaño: "Grande",
    N_personas: "90",
    Estado: "Disponible",
    img: Comedor,
    img2: Comedor,
  }
  ];

function Cards() {
  return (
    <div>
      <Navbar2 />
      <div className='container d-flex flex-wrap'>
        {cards.map((card) => (
          <div className='col-md-4 p-2' key={card.id}>
            <Card
              id={card.id}
              Nombre={card.Nombre}
              Edificio={card.Edificio}
              N_piso={card.N_piso}
              Tamaño={card.Tamaño}
              N_personas={card.N_personas}
              Estado={card.Estado}
              img={card.img}
              img2={card.img2}
            ></Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
