import {React,useState,useEffect,forwardRef} from 'react'
import MaterialTable from '@material-table/core'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Print from '@material-ui/icons/Print';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import '../App.css'

import {Redirect} from 'react-router-dom'
import Cookies from 'universal-cookie/es6';
import Navbar2 from './Navbar'

const cookies = new Cookies();
const api=process.env.REACT_APP_API;

function Todos() {
    const [solicitudes, setSolicitudes] = useState([])


    const getSalas = async () => {
        const res = await fetch(`${api}/mostrar`)
        const data = await res.json();
        data.map((result)=>{
            result.id_Solicitud="RS"+result.id_Solicitud;
            result.dia=(result.dia).slice(0,10);
            return result})
        
        setSolicitudes(data)

    }

    useEffect(() => {
        getSalas();

    }, [])

    

    const tableIcons = {
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        Print: forwardRef((props, ref) => <Print {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    };


    const columns = [
        { title: "Código de Solcitud", field: "id_Solicitud" },
        { title: "Edificio", field: "edificio" },
        { title: "Nombre de Sala", field: "nombre_Sala" },
        { title: "N° de piso", field: "n_Piso" },
        { title: "Tamaño de Sala", field: "Tamaño_sala" },
        { title: "Día", field: "dia" },
        { title: "Hora de Inicio", field: "hora_Inicio" },
        { title: "Hora de Finalización", field: "hora_Fin" },
        { title: "Estado", field: "confirmacion" },
        { title: "Usuario", field: "correo_Electronico" },
    ];

    if(cookies.get("nombre_Rol")==="Usuario"){
        return <Redirect to="/inicio"></Redirect>
    }else{

return (

    <div> 
        <Navbar2/>
    <div className='table-responsive'>
        <MaterialTable
            options={{
                actionsColumnIndex: -1, headerStyle: {
                    backgroundColor: '#4484ce',
                    color: '#FFF'
                },
                maxBodyHeight: '600px'
            }}
            icons={tableIcons}
            data={solicitudes}
            columns={columns}
            title="Solicitud de Reserva de Salas"
            localization={{
                pagination: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsPerPage: "resultados por página",
                    firstAriaLabel: "Primera página",
                    firstTooltip: "Primera página",
                    previousAriaLabel: "página anterior",
                    previousTooltip: "página anterior",
                    nextAriaLabel: "Siguiente página",
                    nextTooltip: "Siguiente página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                    labelRowsSelect: "Filas"
                },
                toolbar: {
                    nRowsSelected: '{0} row(s) selected'
                },
                header: {
                    actions: 'Acción'
                },
                body: {
                    emptyDataSourceMessage: 'No se encontraron registros',
                    filterRow: {
                        filterTooltip: 'Filter'
                    }
                }
            }}
        >

        </MaterialTable>
    </div>
    </div>
)
}
}

export default Todos
