import {React} from 'react'
import Cards from './components/Cards'
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import Inicio from './components/Inicio';
import Cards2 from './components/Card2';
import Cards3 from './components/Cards3';
import Cards4 from './components/Cards4';
import Ver from './components/Ver';
import Datas from './components/Datas';
import Plazas from './components/Plazas';
import Solicitudes from './components/Solicitudes';
import Todas from './components/Todos';
import CrearReserva from './components/CrearReserva';
import Login from './components/Login';
import './App.css'
import Formulario from './components/Formulario';
import Zonadescarga from './components/Zonadescarga';
import PrivateRoute from './components/PrivateRoute';
import Disponibles from './components/Disponibles';
import Usuarios from './components/Usuarios';
import Editarinfo from './components/Editarinfo';
import Visitas from './components/Visitas';
import Cancelar from './components/Cancelar';


function App() {

  return (
    <Router>
      <div className="App">
        <Switch>

          <PrivateRoute path="/torre1" component={Cards}/>

          <PrivateRoute path="/torre2" component={Cards2}/>
               
          <PrivateRoute path="/cbb" component={Cards3}/>
          
          <PrivateRoute path="/cbc" component={Cards4}/>
            
          <PrivateRoute path="/datacenter" component={Datas}/>
          
          <PrivateRoute path="/zonadescarga" component={Zonadescarga}/>

          <PrivateRoute path="/plazas" component={Plazas}/>
            
          <PrivateRoute path="/ver" component={Ver}/>

          <PrivateRoute path="/solicitudes" component={Solicitudes}/>

          <PrivateRoute path="/cancelarReserva" component={Cancelar}/>
  

          <PrivateRoute path="/crearReserva" component={CrearReserva}/>

          <PrivateRoute path="/todas" component={Todas}/>

          <PrivateRoute path="/inicio" component={Inicio}/>
          
          <PrivateRoute path="/disponibles" component={Disponibles}/>

          <PrivateRoute path="/usuarios" component={Usuarios}/>
    
          <PrivateRoute path="/editar" component={Editarinfo}/>

          <PrivateRoute path="/visitas" component={Visitas}/>
    
          <Route path="/Formulario" component={Formulario} />

          <Route path="/" component={Login} />
        </Switch>
      </div>
    </Router>
  );
}

export default App
