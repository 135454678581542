import React from 'react'
import Card from './Card'
import Navbar2 from './Navbar'
import Cookies from 'universal-cookie/es6';
import {Redirect} from 'react-router-dom'
import sala1 from '../assets/cbcSala1.jpg'
import sala2 from '../assets/cbcSala2.jpg'
import sala3 from '../assets/cbcSala3.jpg'
import sala4 from '../assets/cbcSala4.jpg'
import sala5 from '../assets/cbcSala5.jpg'
import sala6 from '../assets/cbcSala6.jpg'
import Comedor from '../assets/CBCComedor.jpg'


const cards=[
    {
        id:13,
        Nombre:'Sala 1 ',
        Edificio:'CBC',
        N_piso:'7',
        Tamaño:'Pequeña',
        N_personas:'5',
        Estado:'Disponible',
        img:sala1,
        img2:sala1
    },
    {
        id:14,
        Nombre:'Sala 2',
        Edificio:'CBC',
        N_piso:'7',
        Tamaño:'Pequeña',
        N_personas:'4',
        Estado:'Disponible',
        img:sala2,
        img2:sala2       
    },
    {
        id:15,
        Nombre:'Sala 3',
        Edificio:'CBC',
        N_piso:'7',
        Tamaño:'Grande',
        N_personas:'18',
        Estado:'Disponible',
        img:sala3,
        img2:sala3
    },{
        id:16,
        Nombre:'Sala 4',
        Edificio:'CBC',
        N_piso:'7',
        Tamaño:'Grande',
        N_personas:'18',
        Estado:'Disponible',
        img:sala4,
        img2:sala4
      
    },
    {
        id:17,
        Nombre:'Sala 5',
        Edificio:'CBC',
        N_piso:'7',
        Tamaño:'Pequeña',
        N_personas:'4',
        Estado:'Disponible',
        img:sala5,
        img2:sala5
    },
    {
        id:27,
        Nombre:'Sala 6',
        Edificio:'CBC',
        N_piso:'7',
        Tamaño:'Grande',
        N_personas:'18',
        Estado:'Disponible',
        img:sala6,
        img2:sala6
      
    },{
        id:31,
        Nombre:'Comedor',
        Edificio:'CBC',
        N_piso:'7',
        Tamaño:'Grande',
        N_personas:'70',
        Estado:'Disponible',
        img:Comedor,
        img2:Comedor
    }
]



function Cards4() {
    return (
        <div>
           <Navbar2/>
            <div className="container d-flex flex-wrap">
            
                 {         
                  cards.map(card=>(
                    <div className="col-md-4 p-2" key={card.id}>
                    <Card id={card.id} Nombre={card.Nombre} Edificio={card.Edificio} N_piso={card.N_piso} Tamaño={card.Tamaño} N_personas={card.N_personas} Estado={card.Estado} img={card.img} img2={card.img2} ></Card>
                    {
                    }
                    </div>
                  ))
              }        
           
           
        </div>
     
        </div>
    )
            }


export default Cards4
