import React from 'react'
import Data from './Data'
import Navbar2 from './Navbar'
import defaultImagen from '../assets/DSCF1032.jpg'
const cards=[
    {
        id:20,
        Nombre:'Sala Blanca 1',
        Edificio:'Data Center',
        N_piso:'Sotano 0(S0)',
        Tamaño:'No aplica',
        N_personas:'4',
        Estado:'Disponible',
        img:defaultImagen,
        img2:defaultImagen,
        
    },
    {
        id:21,
        Nombre:'Sala Blanca 2',
        Edificio:'Data Center',
        N_piso:'Sotano 0(S0)',
        Tamaño:'No Aplica',
        N_personas:'4',
        Estado:'Disponible',
        img:defaultImagen,
        img2:defaultImagen
    },
    {
        id:22,
        Nombre:'Sala Blanca 3',
        Edificio:'Data Center',
        N_piso:'Sotano 0(S0)',
        Tamaño:' No Aplica',
        N_personas:'4',
        Estado:'Disponible',
        img:defaultImagen,
        img2:defaultImagen
    },
    {
        id:26,
        Nombre:'Cuarto de Proveedores',
        Edificio:'Data Center',
        N_piso:'Sotano 0(S0)',
        Tamaño:'No aplica',
        N_personas:'4',
        Estado:'Disponible',
        img:defaultImagen,
        img2:defaultImagen,
    }
    ,{
        id:23,
        Nombre:'Visita General',
        Edificio:'Data Center',
        N_piso:'Sotano 0(S0)',
        Tamaño:'No aplica',
        N_personas:'4',
        Estado:'Disponible',
        img:defaultImagen,
        img2:defaultImagen,
    }
]





function Datas() {
    return (
        <div>
            <Navbar2/>
        <div className="container d-flex flex-wrap">
              {
                  cards.map(card=>(
                    <div className="col-md-4 p-2" key={card.id}>
                    <Data id={card.id}  Nombre={card.Nombre} Edificio={card.Edificio} N_piso={card.N_piso} Tamaño={card.Tamaño} N_personas={card.N_personas} Estado={card.Estado} img={card.img} img2={card.img2} ></Data>
                    </div>
                    
                  ))
              }  
            </div>    
  
            </div>           
       
    )
}


export default Datas
