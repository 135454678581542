import {React,useEffect,useState} from 'react'
import {Form,Button} from "react-bootstrap";
import swal from 'sweetalert';
import Navbar2 from './Navbar'
import {Redirect} from 'react-router-dom'
import Cookies from 'universal-cookie/es6';
const api=process.env.REACT_APP_API;

function CrearReserva() {
    const cookies=new Cookies();
    const rol=cookies.get("nombre_Rol");
    const [Edificio,setEdificio]=useState("");
    const [listE,setListaE]=useState([])
    const [listU,setListaU]=useState([])
    const [dsala,setDsala]=useState(true)
    const [nombre_E,setNombre_E]=useState([])
    const [nombre_S,setNombre_S]=useState("")
    const [dia,setDia]=useState("")
    const [inicio,setInicio]=useState("")
    const [fin,setFin]=useState("")
    const [usuario,setUsuario]=useState("")
    const [motivo,setMotivo]=useState("")
    const [mensaje,setMensaje]=useState("")
    const [mostrar,setMostrar]=useState(false)


    const consultarEdificio=async()=>{
        const res = await fetch(`${api}/edificios`)
        const data = await res.json();
        setListaE(data)
    }

    const consultarUsuario=async()=>{
      const res = await fetch(`${api}/cUsuario`)
      const data = await res.json();
      setListaU(data)
  }


    const handleSubmit =async() => {
      const edificio=Edificio
      const nombre=nombre_S
      const res = await fetch(`${api}/consultarSala`, {
        
        method: 'put',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rol,
          edificio,
          nombre,
          dia,
          inicio,
          fin

        })
    })                      

    const data = await res.json();
    const mostrar=data[0]

    if(mostrar.length===0){
      const estado="Aceptada"
      fetch(`${api}/agregar2`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              Edificio,
              nombre_S,
              dia,
              inicio,
              fin,
              usuario,
              mensaje,
              motivo,
              estado
          })
      })
    }else{
      swal({
        title: "Solicitud de Reserva Rechazada",
        text: "No se Puede Aceptar Porque ya Existe Una Reserva que Crea Conflicto",
        icon: "error",
        button: "Aceptar"
    })
  }

  }

 

const alerta=()=>{
  swal({
    title: "Reserva de Sala Creada",
    icon: "success",
    button: "Aceptar",
  });

  setEdificio("")
  setNombre_S("")
  setDia("")
  setInicio("")
  setFin("")
  setUsuario("")
  setMensaje("")
  setMotivo("")
}


    const enviarDatos=(e)=>{
        e.preventDefault();
        if(Edificio==="" || nombre_S==="" || dia==="" || inicio==="" || fin==="" || usuario===""){
            swal({
                title: "Validacion de Datos",
                text: "Todos los Campos Deben de Estar completos",
                icon: "warning",
                button: "Aceptar"
            })
        }else{
          
          //inicio del segundo if
          if (Edificio === "Data Center" && mostrar === false) {
            swal({
              title: "Ingrese el Motivo de la Visita",
              icon: "info",
              button: "Aceptar",
            });
            setMostrar(true);
            
          } else if (Edificio === "Data Center" && mostrar === true) {
            if (mensaje === "") {
              swal({
                title: "Validacion de Datos",
                text: "No Ingresó el Motivo de la Visita",
                icon: "warning",
                button: "Aceptar",
              });
            } else {
              handleSubmit();
              alerta()

            }
          } else {
            //fin del segundo if
        
            setMostrar(false);
            handleSubmit();
            alerta()
          }

        }//fin del primer if
    }

  

    useEffect(() => {
      consultarEdificio();
      consultarUsuario();
     
    }, [])

    if(cookies.get("nombre_Rol")==="Usuario"){
      return <Redirect to="/inicio"></Redirect>
  }else{
    
    
    return (
      <div  style={{height:"100vh"}}>
        <Navbar2/>
     
      <div className="d-flex align-items-center justify-content-center" >
        <Form  style={{
             boxShadow: "0px 10px 10px 0px black",
             paddingRight:"20px",
             paddingLeft:"20px",
             marginTop:"40px"
        }} onSubmit={enviarDatos} >
            <h4 className='text-center mt-2'>Ingrese los Datos Para Crear Reserva de Sala</h4>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className='mb-0'>Edificio</Form.Label>
            <Form.Select aria-label="Seleccione una Opcion"   value={Edificio} onChange={async (e)=>{setDsala(false);setEdificio(e.target.value); 
            
            const nombre=e.target.value
            const res2 =await fetch(`${api}/sala`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    nombre
                })

            })

            const data = await res2.json();
            setNombre_E(data)
        }
                }>
              <option>Seleccione una Opción</option>
              {
                  listE.map(lista=>(
                      <option key={lista.edificio}>{lista.edificio}</option>
                  )
                )
              }
              
              
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className='mb-0'>Nombre de Sala</Form.Label>
            <Form.Select aria-label="Seleccione una Opcion" value={nombre_S} disabled={dsala} onChange={e => {setNombre_S(e.target.value)}} >
              <option>Seleccione una Opción</option>
              {
                  nombre_E.map(lista=>(
                      <option key={lista.nombre_Sala}>{lista.nombre_Sala}</option>
                  )
                )
              }
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className='mb-0'>Dia de Reserva</Form.Label>
            <Form.Control type="date" value={dia} onChange={e => {setDia(e.target.value)}}/>
          </Form.Group>

          <Form.Group className="mb-0" controlId="exampleForm.ControlTextarea1">
            <Form.Label  style={{width:"350px", marginRight:"0px",marginBottom:"0px"}} >Hora de Inicio</Form.Label>
            <Form.Label style={{marginLeft:"27px",marginBottom:"0px"}}>Hora de Finalización</Form.Label>
          </Form.Group>


          <Form.Group className="mb-3 d-flex" controlId="exampleForm.ControlTextarea1">
            <Form.Control style={{width:"350px", marginRight:"0px"}} type="time" value={inicio} onChange={e => {setInicio(e.target.value)}}/>
            <Form.Control style={{width:"350px",marginLeft:"27px",marginRight:"0px"}} type="time" value={fin} onChange={e => {setFin(e.target.value)}}/>
          </Form.Group>


         

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className='mb-0'>Nombre de Usuario</Form.Label>
            <Form.Select type="text" value={usuario} onChange={e => {setUsuario(e.target.value)}}>
              <option>Seleccione una Opción</option>
              
              {
                  listU.map(lista=>(
                      <option key={lista.correo_Electronico}>{lista.correo_Electronico}</option>
                  )
                )
              }

             </Form.Select> 

          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className='mb-0'>Mensaje de Reserva</Form.Label>
            <Form.Control type="text" value={mensaje} onChange={e => {setMensaje(e.target.value)}}/>
          </Form.Group>

          {

            mostrar?(
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className='mb-0'>Motivo de visita</Form.Label>
            <Form.Control type="text" value={motivo} onChange={e => {setMotivo(e.target.value)}}/>
          </Form.Group>

              )
            :null}


          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Button className='col-md-12 mb-2' variant='success' type='submit'>Crear reserva</Button>
          </Form.Group>


        

        </Form>
      </div>
      </div>
    );
}
}

export default CrearReserva
