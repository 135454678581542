import React, { useState, useEffect, forwardRef } from 'react'
import { Button, Modal} from "react-bootstrap";
import MaterialTable from '@material-table/core'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Print from '@material-ui/icons/Print';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import Message from '@material-ui/icons/Message';
import swal from 'sweetalert';
import Cookies from 'universal-cookie/es6';
import Navbar2 from './Navbar'
import { faBreadSlice } from '@fortawesome/free-solid-svg-icons';
const api = process.env.REACT_APP_API;

function Ver() {
    const cookies=new Cookies();
    const correo_Electronico=cookies.get("correo_Electronico")

    const [lgShow, setLgShow] = useState(false);
    const [lgShow2, setLgShow2] = useState(false);
    const [pendiente] = useState("Pendiente")
    const [solicitudes, setSolicitudes] = useState([])
    const [solicitudes2, setSolicitudes2] = useState([])
    const [solicitudes3, setSolicitudes3] = useState([])
    const [solicitudes4, setSolicitudes4] = useState([])
    const [solicitudes5, setSolicitudes5] = useState([])
    const [setSolicitudes6] = useState("")
    const [mostrar, setMostrar] = useState(false);
    const [mostrar2, setMostrar2] = useState(false);
    const [mostrar3, setMostrar3] = useState(false);
    const [mostrar4, setMostrar4] = useState(false);
  
  
    const [codigoS, setCodigoS] = useState("");

    const [nombre1, setNombre1] = useState("");
    const [identidad1, setIdentidad1] = useState("");

    const [nombre2, setNombre2] = useState("");
    const [identidad2, setIdentidad2] = useState("");

    const [nombre3, setNombre3] = useState("");
    const [identidad3, setIdentidad3] = useState("");

    const [nombre4, setNombre4] = useState("");
    const [identidad4, setIdentidad4] = useState("");




    const handleSubmit = async () => {
        operacion()
        const res = await fetch(`${api}/consultarReservas`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              correo_Electronico
            })
        })

        const data = await res.json();

        data.map((result)=>{
          result.id_Solicitud="RS"+result.id_Solicitud;
          result.dia=(result.dia).slice(0,10);
          return result})
        setSolicitudes(data);
    }

    const handleSubmit2 = async () => {
      operacion2()
        const res = await fetch(`${api}/consultarReservas2`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              correo_Electronico,
                pendiente
            })
        })
       
       const  data2 = await res.json();
   
       data2.map((result)=>{
        result.id_Solicitud="RS"+result.id_Solicitud;
        result.dia=(result.dia).slice(0,10);
        return result})
        setSolicitudes2(data2);
    }


    const handleSubmit3 = async () => {
      operacion3()
        const res = await fetch(`${api}/consultarReservas3`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              correo_Electronico
            })
        })

        const data2 = await res.json();
        data2.map((result)=>{
          result.id_Solicitud="RS"+result.id_Solicitud;
          result.dia=(result.dia).slice(0,10);
          return result})
        setSolicitudes3(data2);
    }

    const handleSubmit4 = async () => {
      operacion4();
        const res = await fetch(`${api}/consultarData`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              correo_Electronico
            })
        })

        const data2 = await res.json();
        data2.map((result)=>{
          result.id_Solicitud="RS"+result.id_Solicitud;
          result.dia=(result.dia).slice(0,10);
          return result})
        setSolicitudes4(data2);
    }

    const tableIcons = {
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        Print: forwardRef((props, ref) => <Print {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        RemoveRedEyeIcon: forwardRef((props, ref) => <RemoveRedEye {...props} ref={ref} />),
        Message: forwardRef((props, ref) => <Message {...props} ref={ref} />)
           
    };

    const columns = [
        { title: "Código de Solicitud", field: "id_Solicitud" },
        { title: "Edificio", field: "edificio" },
        { title: "Nombre de Sala", field: "nombre_Sala" },
        { title: "N° de Piso", field: "n_Piso" },
        { title: "Tamaño de Sala", field: "Tamaño_sala" },
        { title: "Día", field: "dia"   },
        { title: "Hora de Inicio", field: "hora_Inicio" },
        { title: "Hora de Finalización", field: "hora_Fin" },
        { title: "Estado", field: "confirmacion" },
        { title: "Usuario", field: "correo_Electronico" },
    ];

    const columns2 = [
        { title: "Código de Solicitud", field: "id_Solicitud" },
        { title: "Nombre de visita", field: "Nombre" },
        { title: "Número de identidad", field: "id" }
    ];

    const operacion = () => {
        setMostrar(!mostrar)
    }

    const operacion2 = () => {
        setMostrar2(!mostrar2)
    }

    const operacion3 = () => {
        setMostrar3(!mostrar3)
    }


    const operacion4 = () => {
        setMostrar4(!mostrar4)
    }



    const enviarDatos = async () => {
     
        
       const res= await fetch(`${api}/agregarVisitas`, {
            method: 'Post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 
                codigoS,
                nombre1,
                identidad1,
                nombre2,
                identidad2,
                nombre3,
                identidad3,
                nombre4,
                identidad4,
            })
        })       
        
        const data=await res.json();
        console.log(data);
        if(data==="Correcto"){
          setLgShow(false);
          swal({
            title: "Visitas agregadas correctamente.",
            
            icon: "info",
            button: "Aceptar",
          });
  
        }
                    
    }

  




    useEffect(() => {
    }, [solicitudes4])



    return (
      <div>
        <Navbar2/>
      <div className=" container p-2">
        <div className="d-grid gap-2">
          <Button variant="primary" onClick={handleSubmit} size="lg">
            Mis Solicitudes
          </Button>

          {mostrar ? (
            <div className="table-responsive">
              <MaterialTable
                options={{
                  actionsColumnIndex: -1,
                }}
                icons={tableIcons}
                data={solicitudes}
                columns={columns}
                title="Mis Solicitudes"
                localization={{
                  onPageChange: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsPerPage: "resultados por página",
                    firstAriaLabel: "Primera página",
                    firstTooltip: "Primera página",
                    previousAriaLabel: "página anterior",
                    previousTooltip: "página anterior",
                    nextAriaLabel: "Siguiente página",
                    nextTooltip: "Siguiente página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    nRowsSelected: "{0} row(s) selected",
                  },
                  header: {
                    actions: 'Acción'
                },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}

                actions={[{
                  icon: Message,
                    tooltip: "Ver Comentario",
                    onClick: async (event, rowdata) => {
                      const codigoSC=rowdata.id_Solicitud;
                     
                      const res = await fetch(`${api}/verComentario`, {
                        method: 'put',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                           codigoSC
                        })
                        
                    }) 

                    const data2 = await res.json();
                   console.log(data2)
                   
                  if(data2.length>0){
                   
                    swal({
                      title: "Comentario",
                      text: `${data2[0].comentario}`,
                      icon: "info",
                      button: "Aceptar",
                    });
                  }else{
                    swal({
                      title: "Comentario",
                      text: "No se encontró comentario de la solicitud",
                      icon: "info",
                      button: "Aceptar",
                    });
                  }
                 
                    
                    }
                }]}
              ></MaterialTable>
            </div>
          ) : null}
          <Button variant="secondary" onClick={handleSubmit2} size="lg">
            Mis Solicitudes Pendientes
          </Button>

          {mostrar2 ? (
            <div className="table-responsive">
              <MaterialTable
                options={{
                  actionsColumnIndex: -1,
                }}
                icons={tableIcons}
                data={solicitudes2}
                columns={columns}
                title="Mis Solicitudes de Reserva Pendientes"
                localization={{
                  onPageChange: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsPerPage: "resultados por página",
                    firstAriaLabel: "Primera página",
                    firstTooltip: "Primera página",
                    previousAriaLabel: "página anterior",
                    previousTooltip: "página anterior",
                    nextAriaLabel: "Siguiente página",
                    nextTooltip: "Siguiente página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    nRowsSelected: "{0} row(s) selected",
                  },
                  header: {
                    actions: "Acción",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
               
              ></MaterialTable>
            </div>
          ) : null}
          <Button variant="dark" onClick={handleSubmit4} size="lg">
            Data Center
          </Button>

          {mostrar4 ? (
            <div className="table-responsive">
              <MaterialTable
                options={{
                  actionsColumnIndex: -1,
                }}
                icons={tableIcons}
                data={solicitudes4}
                columns={columns}
                title="Mis Solicitudes Aceptadas Data Center"
                localization={{
                  onPageChange: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsPerPage: "resultados por página",
                    firstAriaLabel: "Primera página",
                    firstTooltip: "Primera página",
                    previousAriaLabel: "página anterior",
                    previousTooltip: "página anterior",
                    nextAriaLabel: "Siguiente página",
                    nextTooltip: "Siguiente página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    nRowsSelected: "{0} row(s) selected",
                  },
                  header: {
                    actions: "Acción",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                actions={[
                  {
                    icon: RemoveRedEye,
                    tooltip: "Ver Lista de Visitantes",
                    onClick: async (event, rowdata) => {
                        setLgShow2(true)
                        const id_Solicitudesd=rowdata.id_Solicitud;
                        const res = await fetch(`${api}/consultarVisitas`,{
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json'
                             },
                            body: JSON.stringify({
                                id_Solicitudesd
                        })
                        })
                        const data = await res.json();

                        data.map((result)=>{result.id_Solicitud="RS"+result.id_Solicitud;return result })
                        setSolicitudes5(data);
                    }
                  },
                  {
                    icon: AddBox,
                    tooltip: "Agregar Visitantes",
                    onClick: async(event, rowdata) => {
                      const codigo_S = rowdata.id_Solicitud;
                      const res = await fetch(`${api}/visitasDC`, {
                        method: 'put',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          codigo_S
                        })
                    })                      
            
                    const data = await res.json();

                  
                    if(data[0]==="lleno"){
                        
                      swal({
                        title: "Ya existen Registros de Visitantes Cargados ",
                        text: "Solo se Puede Ingresar una Vez La Información de las Personas que Van a Visitar el Data Center",
                        icon: "warning",
                        button: "Aceptar"
                    })
                    }else if(data[0]==="vacio"){

                      setLgShow(true);
                   
                      setCodigoS(codigo_S);
                    }
                    },
                  },
                ]}
              ></MaterialTable>
            </div>
          ) : null}

          <Button variant="danger" onClick={handleSubmit3} size="lg">
            Cancelar mis Solicitudes/Reservas
          </Button>
          {mostrar3 ? (
            <div className="table-responsive">
              <MaterialTable
                options={{
                  actionsColumnIndex: -1,
                }}
                icons={tableIcons}
                data={solicitudes3}
                columns={columns}
                title="Cancelar Solicitud/Reserva de Sala"
                localization={{
                  onPageChange: {
                    labelDisplayedRows: "{from} - {to} de {count}",
                    labelRowsPerPage: "resultados por página",
                    firstAriaLabel: "Primera página",
                    firstTooltip: "Primera página",
                    previousAriaLabel: "página anterior",
                    previousTooltip: "página anterior",
                    nextAriaLabel: "Siguiente página",
                    nextTooltip: "Siguiente página",
                    lastAriaLabel: "Última página",
                    lastTooltip: "Última página",
                    labelRowsSelect: "Filas",
                  },
                  toolbar: {
                    nRowsSelected: "{0} row(s) selected",
                  },
                  header: {
                    actions: "Acción",
                  },
                  body: {
                    emptyDataSourceMessage: "No se encontraron registros",
                    filterRow: {
                      filterTooltip: "Filter",
                    },
                  },
                }}
                actions={[
                  {
                    icon: DeleteOutline,
                    tooltip: "Cancelar Solicitud/Reserva",
                    onClick: (event, rowdata) => {
                      const cancelar = "Cancelada";
                      const correo=rowdata.correo_Electronico
                      const id = rowdata.id_Solicitud;
                      const edificio = rowdata.edificio;
                      const nombre = rowdata.nombre_Sala;
                      const dia = rowdata.dia;
                      const inicio = rowdata.hora_Inicio;
                      const fin = rowdata.hora_Fin;
                      fetch(`${api}/cancelar`, {
                        method: "delete",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          correo,
                          cancelar,
                          id,
                          edificio,
                          nombre,
                          dia,
                          inicio,
                          fin,
                        }),
                      });

                      swal({
                        title: "Estado de Solicitud de Reserva",
                        text: "Se Realizó el Cambio de Estado de la Solicitud de Reserva",
                        icon: "success",
                        button: "Aceptar",
                      });
                    },
                  },
                ]}
              ></MaterialTable>
            </div>
          ) : null}

          <Modal
            size="lg"
            show={lgShow}
            onHide={() => setLgShow(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton className="App">
              <Modal.Title id="example-modal-sizes-title-lg">
                {" "}
                Ingresar Datos de Visitantes
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="container text-start">
                <div className="row">
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      autoFocus
                      value={nombre1}
                      placeholder="Juan López"
                      onChange={(e) => {
                        setNombre1(e.target.value);
                      }}
                    />
                  </div>
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Número de Identificacion (DNI)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      value={identidad1}
                      placeholder="####-####-#####"
                      onChange={(e) => {
                        setIdentidad1(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      value={nombre2}
                      placeholder="Juan López"
                      onChange={(e) => {
                        setNombre2(e.target.value);
                      }}
                    />
                  </div>
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Número de Identificacion (DNI)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      value={identidad2}
                      placeholder="####-####-#####"
                      onChange={(e) => {
                        setIdentidad2(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      value={nombre3}
                      placeholder="Juan López"
                      onChange={(e) => {
                        setNombre3(e.target.value);
                      }}
                    />
                  </div>
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Número de Identificacion (DNI)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      value={identidad3}
                      placeholder="####-####-#####"
                      onChange={(e) => {
                        setIdentidad3(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Nombre
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      value={nombre4}
                      placeholder="Juan López"
                      onChange={(e) => {
                        setNombre4(e.target.value);
                      }}
                    />
                  </div>
                  <div className="p-2 col-md-6">
                    <label for="dia" className="form-label">
                      Número de Identificacion (DNI)
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="dia"
                      value={identidad4}
                      placeholder="####-####-#####"
                      onChange={(e) => {
                        setIdentidad4(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="row pt-4">
                  <Button variant="success" onClick={enviarDatos}>
                    Guardar
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            size="lg"
            show={lgShow2}
            onHide={() => setLgShow2(false)}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton className="App">
              <Modal.Title id="example-modal-sizes-title-lg">
                {" "}
                Consultar Datos de visitantes
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="container text-start">
                <MaterialTable
                title="Visitas"
                 columns={columns2}
                  data={solicitudes5}
                  icons={tableIcons}
                  options={{search: false}}
                  ></MaterialTable>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      </div>
    );
}

export default Ver

