import {React,forwardRef,useState,useEffect} from 'react';
import Navbar from "./Navbar"
import MaterialTable from '@material-table/core'
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Print from '@material-ui/icons/Print';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ChangeHistoryRounded from '@material-ui/icons/Update';
import "../Appv2"
import { FormGroup,FormLabel,FormControl,Button,Modal, FormSelect} from "react-bootstrap";
import swal from 'sweetalert';
import Cookies from 'universal-cookie/es6';
const api=process.env.REACT_APP_API;

function Usuarios() {
    const cookies=new Cookies();
    const [solicitudes, setSolicitudes] = useState([])
    const [solicitudes2, setSolicitudes2] = useState([])
    const [nombre_Rol,setNombre_Rol]=useState("")
    const [vista_Modal,setVista_Modal]=useState(false)
    const [nombre_Usuario,setNombre_Usuario]=useState("")
    const [nuevoR,setNuevoR]=useState("")
    const [cambio,setCambio]=useState(false)
    const rol=cookies.get("nombre_Rol")
 

    const getUsuarios = async () => {
        const res = await fetch(`${api}/usuarios`)                    

        const data = await res.json();
      
        setSolicitudes(data)
    }

    const getRoles=async()=>{
        const res = await fetch(`${api}/roles`)                    

        const data = await res.json();
      
        setSolicitudes2(data)

    }

    const tableIcons = {
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        Print: forwardRef((props, ref) => <Print {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        ChangeHistoryRounded: forwardRef((props, ref) => <ChangeHistoryRounded {...props} ref={ref} />),
    }
    const columns = [
        { title: "Nombre", field: "nombre" },
        { title: "Apellido", field: "apellido" },
        { title: "Nombre Usuario", field: "nombre_Usuario" },
        { title: "N° Teléfono", field: "telefono" },
        { title: "Correo Electrónico", field: "correo_Electronico" },
        { title: "Nombre Institución", field: "nombre_Institucion" },
        { title: "Área Trabajo ", field: "nombre_Are" },
        { title: "Nombre de Rol", field: "nombre_Rol" },
    ];

const saveChange=async()=>{
    
    if(nuevoR==="" || nuevoR==="Seleccione Una Opción"){
        swal({
            title: "Validacion de Datos",
            text: 'No Seleccionó una Opción en el Campo con Nombre "Nuevo Rol que Tendrá el Usuario".',
            icon: "warning",
            button: "Aceptar"
          })
    }else if(nuevoR===nombre_Rol){
        swal({
            title: "Validacion de Datos",
            text: 'El Usuario ya cuenta con Ese Rol Asignado.',
            icon: "error",
            button: "Aceptar"
          })
    }else{
        
        const res = await fetch(`${api}/actualizarRol`, {
            method: 'put',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nuevoR,
                nombre_Usuario
            })
          })

          const data = await res.json();

          if(data==="Actualizado"){
           
            swal({
                title: "Rol de Usuario Modificado",
                text: 'Se ha modificado el rol de Usuario, Esta Acción Quita u Otorga nuevas opciones al Usuario Dentro del Sistema ',
                icon: "success",
                button: "Aceptar"
              })
              setCambio(!cambio)
              setVista_Modal(false);

          }else if(data==="NoActualizado"){

            swal({
                title: "No se pudo Modificar el rol del Usuario",
                icon: "error",
                button: "Aceptar"
              })
          }

          
    }
}

    useEffect(() => {
        getUsuarios();
      }, [cambio]);
     

  return <div>
      <Navbar/>

      <div className='table responsive'>
      <MaterialTable
      
      options={{
        actionsColumnIndex: -1, headerStyle: {
            backgroundColor: '#4484ce',
            color: '#FFF'
        },
        maxBodyHeight: '600px'
    }}
    icons={tableIcons}
    data={solicitudes}
    columns={columns}
    title="Usuarios"
    localization={{
        pagination: {
            labelDisplayedRows: "{from} - {to} de {count}",
            labelRowsPerPage: "resultados por página",
            firstAriaLabel: "Primera página",
            firstTooltip: "Primera página",
            previousAriaLabel: "página anterior",
            previousTooltip: "página anterior",
            nextAriaLabel: "Siguiente página",
            nextTooltip: "Siguiente página",
            lastAriaLabel: "Última página",
            lastTooltip: "Última página",
            labelRowsSelect: "Filas"
        },
        toolbar: {
            nRowsSelected: '{0} row(s) selected'
        },
        header: {
            actions: 'Acción'
        },
        body: {
            emptyDataSourceMessage: 'No se encontraron registros',
            filterRow: {
                filterTooltip: 'Filter'
            }
        }
    }}

    actions={[
        {
          icon: ChangeHistoryRounded,
          tooltip: "Administrar Rol de Usuario",
          onClick: (event, rowdata) => {

            if(rol==="Administrador DataCenter"){
                const nombreRol=rowdata.nombre_Rol
                setNombre_Rol(nombreRol)
                setNombre_Usuario(rowdata.correo_Electronico)
                setVista_Modal(true)
                getRoles();
            }else{
                swal({
                    title: "Su Usuario No tiene Permisos de Acceder a esta Opción",
                    icon: "error",
                    button: "Aceptar"
                  })
            }
          }
        }
        ]}        
      >
      </MaterialTable>
      </div>

      <Modal
            size="lg"
            show={vista_Modal}
            onHide={() => setVista_Modal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            className="d-flex justify-content-center align-items-center"
          >
            <Modal.Header closeButton className="App">
              <Modal.Title id="example-modal-sizes-title-lg text-center" style={{width:"700px"}}>
                {" "}
                Administrar Rol de Usuario {nombre_Usuario}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body >
              
            <div className="container d-flex justify-content-around">
                <FormGroup>
                    <FormLabel style={{marginBottom:'0px'}}>Rol de Usuario Actual</FormLabel>
                    <FormControl type='text' style={{width:'300px'}} disabled value={nombre_Rol}></FormControl>
                </FormGroup>

                <FormGroup>
                    <FormLabel style={{marginBottom:'0px'}}>Nuevo Rol que Tendrá el Usuario</FormLabel>
                    <FormSelect style={{width:'300px'}}  onClick={e=>{setNuevoR(e.target.value)}}>
                        <option>Seleccione Una Opción</option>
                        {
                            solicitudes2.map(lista=>(
                                <option key={lista.nombre_Rol}>{lista.nombre_Rol}</option>
                               
                            )
                            )

                        }
                    </FormSelect>
                </FormGroup>
            </div>

            <div className='container d-flex justify-content-center'>
                <FormGroup className=''>
                    <Button style={{width:"500px",marginTop:'20px'}} onClick={saveChange}>Guardar Cambio</Button>
                </FormGroup>
            </div>
              
            </Modal.Body>
          </Modal>

  </div>;
}

export default Usuarios;
