import styled from "styled-components";
import imagen from "../assets/AEREA.jpg" 

export const Contenedorimagen=styled.div`
    background-size: cover; 
    background-image: url(${imagen});
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-color:rgb(50,50,50);
    background-blend-mode:soft-light;
    heigth:100%;
`