import {React,useState,useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import {NavLink,Link} from 'react-router-dom'
import Cookies from 'universal-cookie/es6';
import {Navbar,Offcanvas,Nav,NavDropdown} from "react-bootstrap";
import PerfilUsuario from './PerfilUsuario';
import * as XLSX from "xlsx";
const api=process.env.REACT_APP_API;

function Navbar2() {
  const cookies=new Cookies();
  const edificio=cookies.get("Nombre_Edificio")
  const rol=cookies.get("nombre_Rol")
const [block5, setBlock5] = useState(""); 
  const [block6, setBlock6] = useState(""); 
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 
 
  const opcioneNavbar=()=>{
    
    if(rol==="Administrador DataCenter"){
      setBlock6("")
    }else{
      setBlock6("none")  
    }
  }

  const generarReporte=(datos)=>{
    const worksheet = XLSX.utils.json_to_sheet(datos);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "hoja1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, `Reporte_Reserva_Salas.xlsx`);
  }

  const consultaDatos=async()=>{
    

    const res = await fetch(`${api}/generarReporte`)
    const data = await res.json();
    console.log(data);

    data.map((result)=>{
      result["Folio"]="RS"+result["Folio"]
      result["Fecha Respuesta"]=(result["Fecha Respuesta"]).slice(0,10)
      
      return result
    })

    return data
    
  }

  const opcioneUsuriar=()=>{
    if(rol==="Usuario"){
      setBlock5("none");
    }else{
      setBlock5("")
    }
  }


  useEffect(() => {
        
    opcioneNavbar()
    opcioneUsuriar()
  });

  return (
      <div>
      <Navbar bg="dark" variant="dark" expand="lg">
        
          <Navbar.Brand><NavLink className='navbar-brand' exact to="/inicio">RESERVA DE SALAS</NavLink></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink  to="/torre1" className='nav-link'>TORRE 1</NavLink>
              <NavLink className='nav-link'  to="/torre2" >TORRE 2</NavLink>
              <NavLink className='nav-link'  to="/cbb" >CBB</NavLink>
              <NavLink className='nav-link'  to="/cbc" >CBC</NavLink>
              <NavLink className='nav-link'  to="/datacenter" >VISITAS DATACENTER</NavLink>
              <NavLink className='nav-link'  to="/zonadescarga" >ZONAS DE DESCARGA</NavLink>
              <NavDropdown title="Solicitudes" id="collasible-nav-dropdown">
                <Link className="dropdown-item"  to="/ver">Ver Solicitudes</Link>
                <Link className="dropdown-item"  to="/disponibles">Disponibilidad de Salas</Link>
                <Link className="dropdown-item"  style={{display:`${block5}`}}  to="/solicitudes">Aceptar/Rechazar Solicitudes</Link>
                <Link className="dropdown-item" style={{display:`${block5}`}}  to="/cancelarReserva">Cancelar Reserva</Link>
                <Link className="dropdown-item" style={{display:`${block5}`}}  to="/crearReserva">Crear Reserva de Sala</Link>
                <Link className="dropdown-item" style={{display:`${block6}`}}  to="/visitas">Solicitudes Aceptadas DC</Link>
                <NavDropdown.Divider style={{display:`${block5}`}}></NavDropdown.Divider>
                <Link className="dropdown-item"  style={{display:`${block5}`}}  to="/usuarios">Consultar Usuarios</Link>
                <Link className="dropdown-item"  style={{display:`${block5}`}} no to="/todas">Ver Todas las Solicitudes</Link>
                <button className="dropdown-item" style={{display:`${block5}`}} onClick={()=>{consultaDatos().then(response=>generarReporte(response))}}>Generar Reporte</button>
              </NavDropdown>
            </Nav>
            <Nav>
             
      <Nav.Link onClick={handleShow}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon> MI PERFIL
      </Nav.Link>
    </Nav>
          </Navbar.Collapse>
       
      </Navbar>  
      
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header style={{marginBottom:"0px"}} closeButton>
          <Offcanvas.Title>Mi Perfil</Offcanvas.Title>
        </Offcanvas.Header>
        <div 
          className="text-center"
          style={{ fontSize: "4em", color: "black",marginTop:"0px", marginBottom:"0px" }}
        >
          <FontAwesomeIcon icon={faUser} />
        </div>
        <Offcanvas.Body style={{marginTop:"0px"}}>
         <PerfilUsuario></PerfilUsuario>
        </Offcanvas.Body>
      </Offcanvas>

      </div>
    )
}export default Navbar2
